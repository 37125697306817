<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style="text-align:center;">{{$t('CHILDEN_STUDIES')}}</h5>
              <button type="button" class="close" @click="hideModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="card-body">
                  <div style="margin-bottom:5%;">
                    <span>
                      <strong>{{$t('ADD_CHILD')}}</strong>
                    </span>
                    <input type="text" v-model="newChild" />
                    <button
                      class="btn btn-primary btn-sm myNavInfo next"
                      @click="saveChild()"

                    >{{$t('CONFIRM')}}</button>

                  </div>
                  <select
                    v-if="data.length>0"
                    class="form-control"
                    name
                    id="comboChild"
                    v-on:change="comboClick"
                  >
                    <option
                      v-for="(child,index) in data"
                      :value="index"
                      :selected="indexChild == index"
                    >{{child.name}}</option>
                  </select>
                  <br />
                  <form v-if="data.length>0" action method="post" class="form-horizontal">
                    <div class="form-group row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <strong>{{$t('STUDIES_STARTED_YEARS')}}</strong>
                          </span>
                          <input
                            class="form-control"
                            type="text"
                            v-model="data[indexChild]['year']"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <strong>{{$t('DURATION_STUDIES_YEARS')}}</strong>
                          </span>
                          <input
                            class="form-control"
                            type="text"
                            v-model="data[indexChild]['duration']"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <span class="input-group-addon">

                            <strong>{{$t('ANNUAL_EXPECTED_COST')}}</strong>

                          </span>
                          <BaseInputFormatted 
                            class="form-control"
                            v-model="data[indexChild]['expenses']"
                            :formatOutsideInput="formatOutsideInput"
                            :formatInsideInput="formatInsideInput" 
                            :insideNewvalue="insideNewvalue"
                            :outsideNewvalue="outsideNewvalue"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <span
                @click="deleteChild()"
                class="btn btn-primary btn-sm myNavInfo next"
              >{{$t('DELETE')}}</span>
            </div>
            <div class="modal-footer">
              <span @click="save()" class="btn btn-primary btn-sm myNavInfo next">{{$t('SAVE')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  components: {
    BaseInputFormatted
  },
  data() {
    return {
      data: [],
      indexChild: 0,
      newChild: "",
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$emit("clicked", "school");
    },
    save() {
      this.$emit("saved", {
        name: "school",
        data: this.data,
      });
      this.$emit("clicked", "school");
    },
    comboClick() {
      let lst = document.getElementById("comboChild");
      let str = lst.options[lst.selectedIndex].value;
      this.indexChild = str;
    },
    saveChild() {
      this.data.push({
          name: this.newChild,
	  createdAt: new Date().toISOString()
      });
      this.newChild = "";
      this.indexChild = this.data.length == 1 ? 0 : this.indexChild + 1;
    },
    deleteChild() {
      this.data.splice(this.indexChild, 1);
      this.indexChild = 0;
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
