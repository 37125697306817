<template>
  <div>
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />
    <transition name="fade">
      <Spinner v-show="showSpinner" />
    </transition>
    <transition name="fade">
      <PopUp
        v-show="toastMsg"
        :message="message"
        :question="question"
        @answer="previewPf"
        @closePopUp="previewPf"
      />
    </transition>
    <h4 style="margin-bottom: 15px" id="hnameCli">
      {{ client.name + ' ' + client.lastname }}
    </h4>
    <div class="card">
      <headerDash />
      <div v-if="showAgentProposal" style="padding: 15px;">
        <div class="d-flex justify-content-between" style="padding: 0px 15px">
          <h4>{{$t('UPDATE_AVAILABLE')}}</h4>
          <div>
            <button style="margin-right: 10px;" class="btn btn-sm" @click="cancelProposal">{{$t('CANCEL_')}}</button>
            <button @click="updatePf" class=" btn btn-sm revertBtn">
              <i class="fa fa-check" />
              &nbsp;{{$t('ACCEPT_PROPOSAL')}}
            </button>
          </div>
        </div>
        <div style="padding:15px;">
          <p style="margin-bottom: 5px; text-decoration: underline double">{{$t('MANAGER_COMMENT')}}</p>
          <div class="comment">
            <p v-if="checkForOptimComment">{{basepf.optimization_comment}}</p>
            <p v-else>{{$t('NO_MANAGER_COMMENT')}}</p>
          </div>
        </div>
        <div style="padding: 15px;">
          <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='comparaison'}" @click="switchNavigation='comparaison'">
            {{$t('COMPARAISON')}}
          </button>
          <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='rebalancing'}" @click="switchNavigation='rebalancing'">
            {{$t('ARBITRAGE')}}
          </button>
        </div>
        <Comparaison v-show="switchNavigation==='comparaison'" :portfoliosProps="portfolios" :basepf="basepf"/>
        <Arbitrage v-show="switchNavigation==='rebalancing'" :nav="nav" />
      </div>
      <div v-else class="email-app mb-6" style="min-height: 450px;">
        <navDash :objectifsProps="1" @clicked="clickNav" :nav="nav" />
        <main class="inbox">
          <div class="toolbar">
            <dashboard
              v-if="nav == 'Dash'"
              @revert="revertPf"
              @previewPf="previewPf"
              :showBtnRevert="showBtnRevert"
              :showBtnProposal="showBtnProposal"
              :portfoliosProps="portfolios"
            />
            <objectifs
              v-if="nav == 'Obj'"
              :objectifsProps="client.info.extra_info.objectifs"
              :apportsProps="createApportPropsObj()"
              :currentProps="client.current"
              @saveObjs="saveObjectifs"
              :dateCreationProps="client.created"
            />
            <historique v-if="nav == 'Histo'" />
            <compliance v-if="nav == 'Com'" />
            <Preferences
              v-if="nav == 'Pref'"
              @savePreferences="savePreferences"
              :showButtonSaveProps="true"
            />
            <ESG v-if="nav == 'ESG'" @saveESG="saveESG" @message="showMessage" :saved="savedESG"/>
            <!----- RESUME COMPONENT WILL BE HERE  ---->

              <div v-if="nav=='Resume'" style="padding: 15px;">
                <div style="padding: 15px;">
                  <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='comparaison'}" @click="switchNavigation='comparaison'">
                    {{$t('COMPARAISON')}}
                  </button>
                  <button class="nav-switch" :class="{'nav-switch_focus': switchNavigation==='rebalancing'}" @click="switchNavigation='rebalancing'">
                    {{$t('ARBITRAGE')}}
                  </button>
                </div>
                <Comparaison v-show="switchNavigation==='comparaison'" :portfoliosProps="portfolios" :basepf="basepf" :hide_proposal="true" />
                <Arbitrage v-show="switchNavigation==='rebalancing'" :portfoliosProps="portfolios" />
              </div>
            <!----- END RESUME COMPONENT ---->
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
import { mapActions, mapState } from 'vuex';

import HeaderDash from '@/components/agent/dashboard/header.vue';
import NavDash from '@/components/agent/dashboard/nav.vue';
import Dashboard from '@/components/agent/dashboard/dashboard.vue';
import Objectifs from '@/components/agent/dashboard/objectifs.vue';
import Historique from '@/components/agent/dashboard/historique.vue';
import Compliance from '@/components/agent/dashboard/compliance.vue';
import PopUp from '@/components/autre/popUp.vue';
import Spinner from '../components/autre/spinner';
import Comparaison from '@/components/agent/dashboard/resume/comparaison.vue';
import Arbitrage from '@/components/agent/dashboard/resume/arbitrage.vue';
import Preferences from '@/components/agent/dashboard/resume/preferences.vue';
import ESG from '@/components/agent/dashboard/resume/esg.vue'

Vue.component('compliance', Compliance);
Vue.component('objectifs', Objectifs);
Vue.component('historique', Historique);
Vue.component('dashboard', Dashboard);
Vue.component('headerDash', HeaderDash);
Vue.component('navDash', NavDash);

export default {
  data() {
    return {
      portfolios: {},
      basepf: {},
      nav: 'Dash',
      lgObjectifs: 0,
      toastMsg: false,
      message: this.$i18n.t('VIEW_NEW_WALLET_VERSION'),
      question: true,
      showSpinner: false,
      showBtnRevert: false,
      showBtnProposal: false,
      showAgentProposal: false,
      switchNavigation: 'comparaison',
      savedESG: ""
    };
  },
  components: {
    PopUp,
    Spinner,
    Comparaison,
    Arbitrage,
    Preferences,
    ESG
  },
  watch: {
    nav: async function(){
      if(!this.hasESG()) return
      await this.checkForPfUpdate(token)
    }    
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Info
    }),
  },
  methods: {
    ...mapActions([
      'setBreadLink',
      'setClientInfo',
      'setClientPortfolio',
      'setLogged',
    ]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });
        await this.$http.get(this.baseUrl + 'auth/logout');
        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role == 10) {
          this.initData()
        } else {
          this.setLogged({ isLog: false, role: 0 });
          await this.$http.get(this.baseUrl + 'auth/logout');
          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    createApportPropsObj(){
      let VersmtPeriodik = null
      if(this.client.info.extra_info.apports.ver_progra) {
        VersmtPeriodik = this.formatInt(
          this.client.info.extra_info.apports['ver' + this.client.info.extra_info.ver_progra]
        )
      }else{
        VersmtPeriodik = this.client.info.extra_info.apports.VersmtPeriodik
      }
      let obj = {
        year: this.client.info.extra_info.apports.duree ? this.client.info.extra_info.apports.duree : this.client.info.extra_info.apports.duration,
        initial: this.client.info.extra_info.apports.initial,
        VersmtPeriodik,
        FreqVersmtPeriodik:this.client.info.extra_info.apports.ver_progra ? this.client.info.extra_info.apports.ver_progra : this.client.info.extra_info.apports.FreqVersmtPeriodik
      }
      return obj
    },
    async initData(){
      let token = window.localStorage.getItem('priradToken');
          let client = await this.$http.get(
            this.baseUrl + 'users/dashboard/' + this.$route.params.id,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          if ('objectifs' in client.data.resultat.info.extra_info) {
            this.lgObjectifs = Object.keys(
              client.data.resultat.info.extra_info.objectifs
            ).length;
          } else {
            client.data.resultat.info.extra_info['objectifs'] = {};
            client.data.resultat.info.extra_info['apports'] = {
              initial: '100000',
              duration: '5',
              FreqVersmtPeriodik: '12',
              VersmtPeriodik: '10000',
            };
          }

          if (!('preferences' in client.data.resultat)) {
            client.data.resultat['preferences'] = {};
          }
          if (!('esg' in client.data.resultat)) {
            client.data.resultat['esg'] = {
              introductionQuestion: {
                itemsKeys: [
                  "environment_objective",
                  "social_objective",
                  "other_objective"
                ],
                itemsScores: [0,0,0]
              },
              esgGame: {
                itemsWeights: [0,0,0,0,0],
                itemsKeys: [
                  "esg.climate_change_adaptation_and_mitigation",
                  "esg.biodiversity",
                  "esg.pollution_and_water_resources_preservation",
                  "esg.circular_economy",
                  "esg.other"
                ]
              },
              pai: []
            }
          }

          this.setClientInfo(client.data.resultat);
          this.setClientPortfolio(client.data.portfolio);
          this.portfolios = Object.assign({}, this.portfolios, client.data.portfolio)
          if(!this.hasESG()) return
          await this.checkForPfUpdate(token)
    },
    hasESG(){
      let hasESG = true
      if (!('esgResult' in this.client)) {
        hasESG = false
        this.clickNav("ESG")
        this.toastMsg = true;
        this.message = this.$i18n.t('MISSING_NEW_ESG_SETTINGS');
        this.question = false;
      }
      return hasESG
    },
    async checkForPfUpdate(token) {
      const updateUser = await this.$http.get(
            this.baseUrl +
              'users/checkUpdatePortfolios/' +
              this.$route.params.id,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          if(updateUser.data){
            this.toastMsg = true;
            this.message = this.$i18n.t('VIEW_NEW_WALLET_VERSION');
            this.question = true;
          }else this.checkOldDistributionExist();
    },
    clickNav(val) {
      if(val !== this.nav) this.nav = val;
    },
    async saveESG(){
      try {
        this.showSpinner = true
        await this.$http.post(
          `${this.baseUrl}users/dashboard/update`,
          this.client
        )

        await this.$http.get(
          `${this.baseUrl}users/viewProposal/${this.$route.params.id}`,
          {
              headers: {
                  Authorization: window.localStorage.getItem('priradToken'),
                  company: window.localStorage.getItem("priradCompany")
              },
          }
        );
        this.initData()
        this.savedESG = Date.now()
        this.showSpinner = false

        }catch(err){
          this.showSpinner = false
        }
    },
    async previewPf(yesNo) {
      this.toastMsg = false;

      if (yesNo) {
        this.nav = "Dash"
        this.showSpinner = true;
        let token = window.localStorage.getItem('priradToken');
        let resUpdate = await this.$http.get(
          `${this.baseUrl}users/viewProposal/${this.$route.params.id}`,
          {
            headers: {
              Authorization: token,
              company: window.localStorage.getItem("priradCompany")
            },
          }
        );
        if (resUpdate.data.statusCode === 200) {
          let newPf = resUpdate.data.pf.value
          this.basepf = resUpdate.data.basepf
          this.setClientPortfolio(newPf);
          this.portfolios = Object.assign({}, this.portfolios, newPf)
          this.showSpinner = false;
          this.showAgentProposal = true;
        } else {
          this.showSpinner = false;
          this.toastMsg = true;
          this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
          this.question = false;
        }
      } else {
        this.showBtnProposal = true;
      }
    },
    cancelProposal() {
      this.showAgentProposal = false;
      this.showBtnProposal = true;
    },
    async updatePf() {
      this.showSpinner = true;
        let token = window.localStorage.getItem('priradToken');
        // TO UPDATE PORTFOLIO
        let resUpdate = await this.$http.get(
          `${this.baseUrl}users/updatePortfolio/${this.$route.params.id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (resUpdate.data.statusCode === 200) {
          //Update the data on the screen
          this.showAgentProposal = false;
          this.checkLog();
          this.showBtnProposal = false;
          this.showSpinner = false;
          this.showBtnRevert = true;
        } else {
          this.showSpinner = false;
          this.toastMsg = true;
          this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
          this.question = false;
        }
    },
    async revertPf() {
      this.showSpinner = true;
      let token = window.localStorage.getItem('priradToken');
      let revert = await this.$http.get(
        this.baseUrl + 'users/revertUpdate/' + this.$route.params.id,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (revert.data.status === 200) {
        setTimeout(() => {
          this.checkLog();
          this.showSpinner = false;
          this.showBtnRevert = false;

          let title = this.$t('CANCELLATION');
          let group = 'foo';
          let type = '';
          let text = this.$t('CHANGE_HAVE_BEEN_CANCELED');
          this.$notify({
            group,
            title,
            text,
            type,
          });
        }, 3000);
      } else {
        this.showSpinner = false;
        this.toastMsg = true;
        this.message = $t('ERROR_OCCURRED_TRY_AGAIN');
        this.question = false;
      }
    },
    async checkOldDistributionExist() {
      let token = window.localStorage.getItem('priradToken');
      let check = await this.$http.get(
        this.baseUrl +
          'users/checkOldDistributionExist/' +
          this.$route.params.id,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (check.data.status === 200) {
        check.data.checked ? (this.showBtnRevert = true) : '';
      }
    },
    savePreferences(pref) {
      let client = this.client;
      client['preferences'] = pref;
      client['updatePortfolio'] = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
      this.saveObjectifs();
    },
    saveViews(views) {
      let client = this.client;
      client['views'] = views;
      client['updatePortfolio'] = true;
      this.setClientInfo(client);
      this.showBtnProposal = true;
      this.showBtnRevert = false;
    },
    showMessage(msg){
        this.toastMsg = true;
        this.message = this.$i18n.t(msg);
        this.question = false;
    },
    async saveObjectifs() {
      await this.$http.post(
        this.baseUrl + 'users/dashboard/update',
        this.client
      )
      .then(() => {
        this.$notify({
          group: 'save',
          title: this.$t('SAVE'),
          text: this.$t('VIEWS_SAVED'),
          type: '',
        });
      })
      .catch(() => {
        this.toastMsg = true;
        this.message = this.$i18n.t('AN_ERROR_HAS_OCCURRED');
        this.question = false;
      })
    },
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
      token: (state) => state.token,
      client: (state) => state.client_Info,
      client_portfolios: (state) => state.client_Portfolio,
    }),

    checkForOptimComment: function() {
      return this.basepf.optimization_comment !== undefined && this.basepf.optimization_comment.length > 0
    }
  },
  async mounted() {
    this.setBreadLink([
      'Prime Radiant',
      'CRM',
      'CLIENTS',
      this.$route.params.id,
    ]);
  },
  created() {
    this.checkLog();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.revertBtn {
  background-color: '#82d435';
  border-color: '#82d435';
  color: 'black';
}
.comment {
  padding: 15px;
  background-color: #F1F1F1;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.nav-switch {
  border: none;
  padding: 10px 15px;
  background-color: white;
  cursor: pointer;
}
.nav-switch:focus {
  outline:none !important;
}
.nav-switch_focus{
  border-bottom: 1px solid lightgray;
  background-color: #F1F1F1;
}
</style>
