<template>
  <div>
    <!-- <modal_assets @clicked="hideModal" v-if="isShow" :dataProps="assets" /> -->
    <div style="display: flex; flex-direction: column">
      <div class="container">
        <div class="card" style="width:100%; max-width:750px;">
          <div class="card-header">
            <strong v-if="dataProps.name == null">
              <i class="fa fa-list-alt" style="margin-right: 10px;"></i>
              {{ $t('STATEMENT_ASSETS_CONSOLIDATED_ACCOUNT') }}
            </strong>
            <strong v-else>
              <i class="fa fa-list-alt" style="margin-right: 10px;"></i>
              {{ $t('ASSET_CONDITION') }} {{ $t(dataProps.name) }}
            </strong>
          </div>
          <div class="card-body">
            <table class="table table-bordered table-condensed">
              <tbody>
                <tr>
                  <td>
                    <strong>Date</strong>
                  </td>
                  <td id="tdDate" class="tabRightInfo">{{ dateCreation }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered table-condensed">
              <thead>
                <tr>
                  <th>
                    <i class style></i>
                    {{ $t('YOU_HAVE') }}
                  </th>
                  <th id="thInvest" class="tabRightInfo">{{ totalFormat }} €</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('SUBSCRIPTIONS') }}</td>
                  <td id="tdSub" class="tabRightInfo">{{ inputs }} €</td>
                </tr>
                <tr>
                  <td>{{ $t('WITHDWRAWALS') }}</td>
                  <td id="tdRachats" class="tabRightInfo">{{ withdrawals }} €</td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered table-condensed">
              <thead>
                <tr>
                  <th>
                    <i class style></i>
                    {{ $t('PROFIT_LOSS') }}
                  </th>
                  <th id="thWin" class="tabRightInfo">{{ earn }} €</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="card" style="width:100%; max-width:750px; align-self: center">
          <div class="card-header">
            <strong v-if="dataProps.name == null">
              <i class="fa fa-pie-chart" style="margin-right: 10px;"></i>
              {{ $t('DISTRIBUTION_ACCOUNT') }}
            </strong>
            <strong v-else>
              <i class="fa fa-pie-chart" style="margin-right: 10px;"></i>
              {{ $t('DISTRIBUTION_ACCOUNT') }} {{ $t(dataProps.name) }}
            </strong>
          </div>
          <div class="card-body">
            <div class="chart-wrapper">
              {{ showPie }}
              <mypieChartDonut :widthProps="600" :heightProps="300" :dataProps="portfolio" :translateAssetsProps="translated" :translateUniversProps="universtranslated" :showThirdLayoutBtn="true" />
            </div>
          </div>
          <div class="col-12">
            <div class="row" style="margin-bottom: 20px; margin-top: 20px; text-align: center">
              <div class="col-6 b-r-1">
                <div class="font-weight-bold" id="divTC">{{ totalCompte }} €</div>
                <div class="text-muted">
                  <small>{{ $t('TOTAL_ACCOUNTS') }}</small>
                </div>
              </div>
              <div class="col-6">
                <div class="font-weight-bold" id="divEG">{{ totalFormat }} €</div>
                <div class="text-muted">
                  <small>{{ $t('IN_MANAGEMENT') }}</small>
                </div>
              </div>
            </div>
            <template v-for="(item, name, index) in portfolio" v-if="item.total > 0">
              <div :key="index+'_'+generateUuid">
                <div style="font-size: 16px;">
                  <strong>{{ translateUnivers(name) }}</strong>
                  <span class="font-weight-bold float-right" id="spanFECO">
                    {{
                    Math.round(item.weight * 100)
                    }}
                  </span>
                </div>
                <div class="progress progress-sm mt-2 mb-3">
                  <div
                    class="progress-bar-inner"
                    :style="
                        'width:' +
                        Math.round(item.weight * 100) +
                        '%;background-color: ' +
                        item.color
                      "
                    id="pbFECO"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div style="width:100%; max-width:750px; align-self: center">
        <mylineChart
          v-if="dataProps.bt.length >= 10"
          :dataProps="dataProps.bt"
          :cumioProps="dataProps.cum_io"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import uuidMixin from '@/mixins/uuid'
import PieChart from "@/components/agent/dashboard/dash/piechart.vue";
import Modal_assets from "@/components/autre/modalAssets.vue";
import PieChartDonut from "@/components/autre/donutchart.vue";

Vue.component("mylineChart", () =>
	      import("@/components/agent/dashboard/dash/linechart.vue")
	     );

Vue.component("mypieChartDonut", PieChartDonut);
Vue.component("modal_assets", Modal_assets);
Vue.component("mypieChart", PieChart);

export default {
  mixins: [uuidMixin],
  data() {
	return {
	    date: "",
	    lastLength: "",
	    total: "",
	    portfolio: {},
	    assets: [],
	    isShow: false,
	    universtranslated: [],
	    translated:[]

	};
    },
    props: {
	dataProps: {
	    type: Object,
	    required: true,
	},
	totalCompteProps: {
	    type: Number,
	    required: true,
	},
	baseportfolioProps: {
	    type: String,
	    required: true,
	},
    },
    watch: {
	dataProps: function (value) {
	    let ctx = this;
	    //this.showPie = false;
	    this.date = value.bt[0].date;
	    this.lastLength = value.bt.length;
	    this.total = Math.round(value.bt[this.lastLength - 1].value);
	    this.getPortfolio();
	},
    },
    computed: {
	...mapState({
	    baseUrl: (state) => state.baseUrl,
	}),
	dateCreation() {
	    let options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	    };
	    let current = new Date(this.date);

	    return current.toLocaleDateString("fr-FR", options);
	},
	earn() {
	    var parts = Math.round(
		this.total -
		    this.dataProps.inputs +
		    Math.abs(this.dataProps.withdrawals)
	    )
		.toString()
		.split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	    return parts.join(".");
	},
	withdrawals() {
	    var parts = Math.round(Math.abs(this.dataProps.withdrawals))
		.toString()
		.split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	    return parts.join(".");
	},
	inputs() {
	    var parts = Math.round(this.dataProps.inputs).toString().split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	    return parts.join(".");
	},
	totalFormat() {
	    var parts = this.total.toString().split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	    return parts.join(".");
	},
	totalCompte() {
	    var parts = this.totalCompteProps.toString().split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	    return parts.join(".");
	},
    },
    methods: {
	async getTranslatedUnivers() {
	    let univers_translated = await this.$http.get(
		this.baseUrl + "univers/translated"
	    );
	    this.universtranslated = univers_translated.data.resultat;
	},
	translateUnivers(n) {
	    let idx = this.universtranslated.findIndex((e) => e.fr == n);
	    return idx != -1 ? this.universtranslated[idx][this.$i18n.locale] : n;
	},
	async getPortfolio() {
	    this.portfolio = {};
	    let ctx = this;

	    let func =
		"assetsDistribution" in ctx.dataProps
		? "universe_asset_funds_assetsDistribution"
		: "universe_asset_funds";

	    let data = await this.$http.post(
		this.baseUrl + "cunit/getData",
		{
		    assetsDistribution: ctx.dataProps.assetsDistribution || null,
		    distribution: ctx.dataProps.distribution,
		    company: localStorage.getItem("priradCompany"),
		},
		{
		    headers: {
			function: func,
		    },
		}
	    );

	    for (const [i, iterator] of Object.entries(data.data)) {
		iterator["total"] = iterator["weight"];
	    }
	    this.portfolio = data.data;
	},
	getAssets(val) {
	    this.assets = val;
	    this.isShow = true;
	},
	hideModal() {
	    this.isShow = false;
	},

	async getTranslatedAssets() {
	    let assets_translated = await this.$http.get(
		this.baseUrl + "assets/translated"
	    );
	    this.translated = assets_translated.data.resultat;
	}
    },
    created() {
	this.getTranslatedUnivers();
	this.getTranslatedAssets();
	this.getPortfolio();
    },
    mounted() {
	this.date = this.dataProps.bt[0].date;
	this.lastLength = this.dataProps.bt.length;
	this.total = Math.round(this.dataProps.bt[this.lastLength - 1].value);
    },
};
</script>
<style scoped>
.tabRightInfo {
    text-align: right;
}
.container {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
}
@media screen and (max-width: 1300px) {
    .container {
	flex-wrap: wrap;
    }
}
</style>
