<template>
  <div>
    <div v-if="showTitle" class="d-flex justify-content-between">
        <h5 class="align-self-center m-0">
          <img src="../../../../../public/img/icons/planet-earth.png" class="icon" alt="earth and environment" />
          {{ $t('ESG') }}
        </h5>
    </div>
    <hr />
    <div class="card-body">
        <form action method="post" class="form-horizontal">
            <div class="form-row mb-4 row__container">
                <p class="col-12 mb-0">{{$t('ESG_PREFERENCE')}}: {{numberOfEsgPrefToken}} Token to distribute</p>
                <div v-for="(item, index) in esg.introductionQuestion.itemsKeys" :key="item" class="col input-group">
                    <span class="input-group-addon">{{$t(item)}}</span>
                    <input
                        type="number"
                        min="0"
                        :id="item"
                        :name="item"
                        class="form-control"
                        placeholder
                        :value="esg.introductionQuestion.itemsScores[index]"
                        @change="(e) => setEsgPrefToken(e, index, esg.introductionQuestion.itemsScores[index])"
                    />
                </div>
            </div>
            <div class="form-row row__container mb-4">
                <p v-if="!aggIsEqualToHundred" class="col-12 alert alert-warning text-center warnPosition">
                    {{ $t('AGGREGATION_100') }}
                </p>
                <p v-else class="col-12 mb-0">{{$t("AGGREGATION_100")}}</p>
                <div v-for="(item, index) in esg.esgGame.itemsKeys" :key="item" class="col-6 input-group mb-4">
                    <span class="input-group-addon">{{$t(item)}} (%)</span>
                    <input
                        type="text"
                        :id="item"
                        :name="item"
                        class="form-control"
                        placeholder
                        v-model="esg.esgGame.itemsWeights[index]"
                        @change="newInput()"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="_pai">Pai</label>
                    <select id="_pai" class="form-control" @change="addItem">
                        <option selected>Choose...</option>
                        <option v-for="item in esgPaiList"
                            :key="item"
                            :value="item"
                        >
                            {{$t(item)}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pai__container">
                <div class="pai" v-for="(item, index) in esg.pai" :key="item">
                    {{$t(item)}} <i class="fa fa-times ml-2" style="cursor: pointer" @click="removePai(index)"/>
                </div>
            </div>
        </form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
        numberOfEsgPrefToken: 5,
        esgPaiList: [
            "greenhouse_gas_emission",
            "use_of_non_renewable_energy",
            "emissions_to_water",
            "human_rights",
            "social_and_employee_matters"
        ],
        esg: {}
    }
  },
  props: {
    esgProps: {
        type: Object,
        required: true
    },
    showTitle: {
        type: Boolean,
        required: false,
        default: false,
    }
  },
  watch: {
    esgProps: {
        handler: function() {
            this.esg = this.$_.cloneDeep(this.esgProps)
        },
        immediate: true
    }
  },
  computed: {
    aggIsEqualToHundred(){
        let agg = this.esg.esgGame.itemsWeights.reduce((prev, curr) => prev + Number(curr), 0)
        return agg === 100
    }
  },
  methods: {
    addItem(event){
        if(!this.esg.pai.includes(event.target.value)) {
            this.esg.pai.push(event.target.value)
            this.newInput()
        } 
    },
    removePai(idx){
        this.esg.pai.splice(idx, 1);
        this.newInput()
    },
    newInput(){
        this.$emit("changeESG", this.esg)
    },
    setEsgPrefToken(e, index, item){
        this.esg.introductionQuestion.itemsScores[index] = e.target.value
        let distributedToken = this.esg.introductionQuestion.itemsScores.reduce((prev, curr) => prev + Number(curr), 0)
        
        if(distributedToken > this.numberOfEsgPrefToken) {
            e.target.value = item
            this.esg.introductionQuestion.itemsScores[index] = item
        }

        this.newInput()
    },
  }
};
</script>

<style scoped>
.icon {
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  text-align: center;
}
</style>
