<template>
  <div class="tab-pane active fade show">
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />

    <div v-if="isLoading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-body">
              <div class="loadcontent">
                <div class="loading">
                  <p>{{ $t('LOADING') }}</p>

                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="well" v-if="!errorEncountered">
      <label>
        <strong>
          <i class="fa fa-check"></i>

          {{ $t('VISUALIZATION_PROPOSED_PORTFOLIO') }}
        </strong>
      </label>
      <div class="text-center">
        <span
          class="btn btn-primary next"
          style="cursor: pointer"
          @click="btnClick()"
          >{{ $t('SAVE_ACCOUNT') }}</span
        >
      </div>
      <hr />
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div
            class="chart-wrapper"
            style="margin-bottom: 50px; text-align: center"
          >
            <mypieChartDonut
              :dataProps="portfolio"
              style="max-width: 600px; margin-left: auto; margin-right: auto"
              :translateAssetsProps="translated"
              :translateUniversProps="universtranslated"
              :showThirdLayoutBtn="true"
              @checkedFunds="showFundsCurrent"
            />
          </div>
          <hr />
       </div>

        <div class="col-12" style="margin-top: 3%">
          <div class="row">
            <div class="col-6">
              <template
                v-for="(item, name, index) in portfolio"
                v-if="item.weight > 0"
              >
                <div style="font-size: 16px" :key="index">
                  <strong>{{ translateUnivers(name) }}</strong>

                  <span class="font-weight-bold float-right" id="spanFECO">{{
                    Math.round(item.weight * 100)
                  }}</span>
                </div>
                <div class="progress progress-sm mt-2 mb-3" :key="index">
                  <div
                    class="progress-bar-inner"
                    :style="
                      'width:' +
                        Math.round(item.weight * 100) +
                        '%;background-color: ' +
                        item.color
                    "
                    id="pbFECO"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </template>
              <div class="checkbox-full-btn">
                <input
                  id="showDetailVisu"
                  name="showDetailVisu"
                  type="checkbox"
                  v-model="showAssetDetail"
                />
                <label for="showDetailVisu">{{
                  $t('SEE_DETAILS')
                }}</label>
              </div>
            </div>

            <div class="col-6">
              <div id="diva" style="padding: 0 50px">
                <mybarChart :dataProps="funds" :titleProps="$t('FUNDS')" />
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-12" v-if="showAssetDetail">
          <AssetsWeights
            v-if="!checkedFundsCurrent"
            :dataProps="pfTab"
            :indexProps="0"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
          />
          <FundsWeights
            v-if="checkedFundsCurrent"
            :indexProps="0"
            :translateUniversProps="universtranslated"
            :translateAssetsProps="translated"
            :dataProps="pfTab"
          />
          <hr />
        </div>
        <div class="col-12">
          <h4>ESG</h4>
            <ESGGraph :esgRes="esg" />
          <hr />
        </div>

        <div class="col-12 row">
          <div class="col-2"></div>
          <div class="col-8">
            <backTest :dataProps="backtest" />
          </div>
        </div>
      </div>
      <div class="row"></div>
    </div>
    <p class="alert alert-warning text-center warnPosition" v-else>
      {{ $t('OPTIM_REQUIRED_VISUALISE') }}
    </p>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import Notifications from 'vue-notification';
Vue.use(Notifications);

import PieChartDonut from '@/components/autre/donutchart.vue';
import Modal_assets from '@/components/autre/modalAssets.vue';
import BarChart from '@/components/agent/dashboard/dash/barchart.vue';
import BackTest from '@/components/agent/nouveau_compte/backtest.vue';
import AssetsWeights from '@/components/gerant/assets/assetsweights.vue';
import FundsWeights from '@/components/gerant/assets/fundsweights.vue';
import ESGGraph from '@/components/agent/dashboard/resume/esgGraph.vue'

Vue.component('mypieChartDonut', PieChartDonut);
Vue.component('backTest', BackTest);
Vue.component('modal_assets', Modal_assets);
Vue.component('mybarChart', BarChart);

export default {
  components: {
    AssetsWeights,
    FundsWeights,
    ESGGraph
  },

  data() {
    return {
      portfolio: {},
      pfTab: {},
      portfolioCunit: {},
      groupeAssets: [],
      assets: [],
      isin: {},
      assetsDistribution: {},
      groupePortfolio: [],
      funds: [],
      notFormatedFunds: [],
      backtest: [],
      isShow: false,
      isLoading: true,
      universtranslated: [],
      translated: [],
      checkedFundsCurrent: false,
      showAssetDetail: false,
      errorEncountered: false,
      esg: {},
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
      simulation: (state) => state.simulation,
      risk: (state) => state.risk,
      baseUrl: (state) => state.baseUrl,
      riskVisualisation: (state) => state.riskVisualisation,
    }),
  },
  methods: {
    async getTranslatedAssets() {
      let assets_translated = await this.$http.get(
        this.baseUrl + 'assets/translated'
      );
      this.translated = assets_translated.data.resultat;
    },
    async getTranslatedUnivers() {
      let univers_translated = await this.$http.get(
        this.baseUrl + 'univers/translated'
      );
      this.universtranslated = univers_translated.data.resultat;
    },
    translateUnivers(n) {
      let idx = this.universtranslated.findIndex((e) => e.fr == n);
      return idx != -1 ? this.universtranslated[idx][this.$i18n.locale] : n;
    },
    getNote(){
      return  this.simulation.hasOwnProperty("NoteTotale") ? this.simulation.NoteTotale : this.data.note
    },
    async createClient() {
      let ctx = this;
      let client = this.$_.cloneDeep(this.data)
      client.esg = client.infos.esg
      client.esgResult = this.esg
      try {
        let data = await this.$http.post(
          this.baseUrl + 'users/create',
          {
            client,
            portfolio: ctx.assetsDistribution,
            funds: ctx.isin,
            risk: ctx.risk,
            company: localStorage.getItem('priradCompany'),
          },
          {
            headers: {
              Authorization: localStorage.getItem('priradToken'),
            },
          }
        );
        let title = this.$t('NEW_ACCOUNT');
        let group = 'foo';
        let type = '';
        let text = this.$t('SAVING_SUCCESS');
        this.$notify({
          group,
          title,
          text,
          type,
        });
      } catch (e) {
        let title = this.$t('NEW_ACCOUNT');
        let group = 'foo';
        let type = '';
        let text = this.$t(`ERROR_OCCURRED_TRY_AGAIN`);
        this.$notify({
          group,
          title,
          text,
          type,
        });
      }
    },
    async updateClient() {
      let ctx = this;
      let client = this.$_.cloneDeep(this.data)
      client.esg = client.infos.esg
      client.esgResult = this.esg

      try {
        let data = await this.$http.post(
          this.baseUrl + 'users/updateCompte',
          {
            client,
            risk: ctx.risk,
          },
          {
            headers: {
              Authorization: localStorage.getItem('priradToken'),
            },
          }
        );
        let title = this.$t('NEW_ACCOUNT');
        let group = 'foo';
        let type = '';
        let text = this.$t('SAVING_SUCCESS');
        this.$notify({
          group,
          title,
          text,
          type,
        });
      } catch (e) {
        let title = this.$t('NEW_ACCOUNT');
        let group = 'foo';
        let type = '';
        let text = this.$t(`ERROR_OCCURRED_TRY_AGAIN`);
        this.$notify({
          group,
          title,
          text,
          type,
        });
      }
    },
    async callPortfolio() {
      let ctx = this;

      let data = null

      try {
        let risk = ctx.getNote()
        // Get list of assets
        data = await this.$http.post(
          this.baseUrl + 'cunit/optimisationClient',
          {
            risk,
            company: localStorage.getItem('priradCompany'),
            preferences: ctx.data.contraintes,
          },
          {
            headers: {
              Authorization: localStorage.getItem('priradToken'),
            },
          }
        );
        ctx.assetsDistribution = data.data.data;
        // Get list of ISIN
        data = await this.$http.post(
          this.baseUrl + 'cunit/assetsToFunds',
          {
            distrib_asset: ctx.assetsDistribution,
            preferences: ctx.data.contraintes,
            company: localStorage.getItem('priradCompany'),
            risk,
            esg: this.data.infos.esg
          },
          {
            headers: {
              Authorization: localStorage.getItem('priradToken'),
            },
          }
        );
      } catch (err) {
        if(err.response.data.message === 'Optimisation is required' ){
          this.errorEncountered = true
          this.isLoading = false;
        }
      }
      this.isin = data.data.assets;
      this.esg = data.data.esg

      // Get data for donut
      data = await this.$http.post(
        this.baseUrl + 'cunit/getData',
        {
          assetsDistribution: ctx.assetsDistribution,
          distribution: ctx.isin,
          company: localStorage.getItem('priradCompany'),
        },
        {
          headers: {
            Authorization: localStorage.getItem('priradToken'),
            function: 'universe_asset_funds_assetsDistribution',
          },
        }
      );
      let tmpData = JSON.parse(JSON.stringify(data.data));
      for (const [i, iterator] of Object.entries(data.data)) {
        iterator['total'] = [iterator['weight']];
        tmpData[i]['total'] = [iterator['weight']];
        for (const [_, assetV] of Object.entries(tmpData[i]['assets'])) {
          assetV['base_weights'] = [assetV['weight']];
        }
      }
      this.pfTab = tmpData;
      ctx.portfolio = data.data;

      this.barChartConstruction();
    },

    async barChartConstruction() {
      let ctx = this;
      let groupepf = await this.getGroupePf();
      let barCharArr = {};
      ctx.getBackTest();

      for (let univers of Object.entries(groupepf)) {
        for (let asset of Object.entries(univers[1].assets)) {
          for (let fund of asset[1].funds) {
            if (fund.ISIN in ctx.isin) {
              barCharArr[fund.Name] = ctx.isin[fund.ISIN];
            }
          }
        }
      }

      ctx.funds = barCharArr;
      ctx.notFormatedFunds = ctx.isin;
      ctx.isLoading = false;
    },
    async getBackTest() {
      let obj = {
        FundPortfolio: this.isin,
        CashTicker: 'ELANCLB.FP',
        BacktestLength: 5,
        NbStepsMemory: 252,
        NbStepsRebalancing: 21,
        TgtDrawdown: 0.1,
      };

      let data = await this.$http.post(this.baseUrl + 'azure/functions', obj, {
        headers: {
          Authorization: localStorage.getItem('priradToken'),
          function: 'BackTest',
        },
      });
      this.backtest = data.data.BacktestNav;
      this.isLoading = false;
    },
    async getFullPortfolio() {
      let ctx = this;
      let data = await this.$http.post(
        this.baseUrl + 'cunit/getData',
        {
          company: localStorage.getItem('priradCompany'),
          distribution: ctx.isin,
        },
        {
          headers: {
            Authorization: localStorage.getItem('priradToken'),
            function: 'universe_asset_funds',
          },
        }
      );

      for (const [i, iterator] of Object.entries(data.data)) {
        iterator['total'] = [iterator['weight']];
      }
      this.portfolio = data.data;
    },
    btnClick() {
      if (this.data.isNew) this.createClient();
      else this.updateClient();
    },
    getAssets(val) {
      this.assets = val;
      this.isShow = true;
    },
    hideModal() {
      this.isShow = false;
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany'),
        {
          headers: {
            Authorization: localStorage.getItem('priradToken'),
          },
        }
      );

      return data.data.resultat[0].contents;
    },
    showFundsCurrent(val) {
      this.checkedFundsCurrent = val;
    },
  },
  created() {
    let ctx = this;
    this.$store.watch(
      function(state) {
        return state.new_Compte;
      },
      function(val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
    this.getTranslatedUnivers();
    this.getTranslatedAssets();
    this.callPortfolio();
  },
};
</script>

<style>
.notification.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  color: #495061;
  background: #eaf4fe;
  border: 1px solid #d4e8fd;
}

.notification-title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  color: #eaf4fe;
}

.loadcontent {
  width: 100%;
  height: 100%;
  /* background-color: #171f30; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadcontent .loading {
  width: 150px;
  height: 50px;
  position: relative;
}
.loadcontent .loading p {
  top: 0;
  padding: 0;
  margin: 0;
  color: #5389a6;
  font-family: 'Oxygen', sans-serif;
  animation: text 3.5s ease both infinite;
  font-size: 12px;
  letter-spacing: 1px;
}
@keyframes text {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }
  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }
  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }
  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}
.loadcontent .loading span {
  background-color: #5389a6;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading 3.5s ease both infinite;
}
.loadcontent .loading span:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #a6dcee;
  border-radius: inherit;
  animation: loading2 3.5s ease both infinite;
}
@keyframes loading {
  0% {
    width: 16px;
    transform: translateX(0px);
  }
  40% {
    width: 100%;
    transform: translateX(0px);
  }
  80% {
    width: 16px;
    transform: translateX(64px);
  }
  90% {
    width: 100%;
    transform: translateX(0px);
  }
  100% {
    width: 16px;
    transform: translateX(0px);
  }
}
@keyframes loading2 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }
  40% {
    transform: translateX(0%);
    width: 80%;
  }
  80% {
    width: 100%;
    transform: translateX(0px);
  }
  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
}
</style>
