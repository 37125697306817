<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style="text-align:center;">{{$t('PROMOTION_TRANSMISSION')}}</h5>
              <button type="button" class="close" @click="hideModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="card-body">
                  <div style="text-align:center;">
                    <input
                      v-model.lazy="data.name"
                      type="text"
                      name
                      id
                      :placeholder="$t('NAME_OF_THE_PROJECT')"
                    />
                  </div>
                  <br />
                  <form action method="post" class="form-horizontal">
                    <div class="form-group row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <strong>{{$t('AMOUNT')}}</strong>
                          </span>
                          <input
                            type="text"
                            id="txtModalObjectif"
                            name="txtModalObjectif"
                            class="form-control"
                            v-model.lazy="data.amount"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <strong>{{$t('HORIZON_YEARS')}}</strong>
                          </span>
                          <input
                            type="text"
                            id="txtModalObjectifDuree"
                            name="txtModalObjectifDuree"
                            class="form-control"
                            v-model.lazy="data.duree"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span @click="save()" class="btn btn-primary btn-sm myNavInfo next">{{$t('SAVE')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    data() {
	return {
	    data: {},
	};
    },
    props: {
	dataProps: {
	    type: Object,
	    required: true,
	},
    },
    methods: {
	hideModal() {
	    this.$emit("clicked", "transmi");
	},
	formatData() {
	    let k = Object.keys(this.data);
	    for (let index = 0; index < Object.keys(this.data).length; index++) {
		if (typeof this.data[k[index]] === "boolean") {
		    continue;
		}
		
		if(k[index] != "name" && k[index] != "createdAt"){
		    this.data[k[index]] = this.data[k[index]]
			.toString()
			.replace(" ", "")
			.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		}
      }
    },
    save() {
      //this.formatData();
      this.$emit("saved", {
        name: "transmi",
        data: this.data,
      });
      this.$emit("clicked", "transmi");
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
</style>
