<template>
  <div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="card">
          <div class="card-header">
            <strong>
              <i class="fa fa-user" style="margin-right: 10px"></i>
              {{ $t('LOGIN') }}
            </strong>
          </div>
          <div
            class="card-body"
            style="text-align: center"
            @keypress.enter="btnClick()"
          >
            <div>
              <label for="txtUsername" class="labelFormCenter">
                {{ $t('USERNAME') }}
                <input
                  type="text"
                  id="txtUsername"
                  v-model="username"
                  class="form-control"
                />
              </label>
              <label for="txtPassword" class="labelFormCenter">
                {{ $t('PASSWORD') }}
                <input
                  type="password"
                  id="txtPassword"
                  v-model="password"
                  class="form-control"
                />
              </label>
            </div>

            <div>
              <span
                class="btn btn-primary next"
                style="cursor: pointer"
                @click="btnClick()"
                >{{ $t('CONTINUE') }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  name: 'home',
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      token: (state) => state.token,
      isLog: (state) => state.isLogged,
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    ...mapActions(['setBreadLink', 'setToken', 'setLogged']),
    async btnClick() {
      let ctx = this;
      try {
        let log = await this.$http.post(this.baseUrl + 'auth/login', {
          username: ctx.username,
          password: Buffer.from(ctx.password).toString('base64'),
        });
        if (log.data.resultat != 'Mauvaise combinaison') {
          this.setToken(log.data.token);
          this.setLogged({
            isLog: true,
            role: log.data.resultat.role,
          });

          // window.location.href to avoid Mozilla Shitfox to ask to save the password
          if (log.data.resultat.role == 10) {
            //for generali force generali as company since mnnormanddeaubry is LCL user
            localStorage.setItem('priradCompany', 'generali');
            localStorage.setItem('priradToken', log.data.token);
            localStorage.setItem('priradRole', log.data.resultat.role);
            window.location.href = '/';
          } else if (log.data.resultat.role == 20) {
            //for generali force generali as company since mnnormanddeaubry is LCL user
            localStorage.setItem('priradCompany', 'generali');
            localStorage.setItem('priradToken', log.data.token);
            localStorage.setItem('priradRole', log.data.resultat.role);
            window.location.href = '/references';
          } else if (log.data.resultat.role == 45) {
            localStorage.setItem('priradApiToken', log.data.token);
            localStorage.setItem('priradApiRole', log.data.resultat.role);
            document.cookie =
              'priradApiId=' +
              log.data.resultat.id +
              ';expires=' +
              10 * 365 * 24 * 60 * 60 * 1000;
            window.location.href = '/users';
          } else if (log.data.resultat.role == 60) {
            localStorage.setItem('priradApiToken', log.data.token);
            localStorage.setItem('priradApiRole', log.data.resultat.role);
            document.cookie =
              'priradApiId=' +
              log.data.resultat.id +
              ';expires=' +
              10 * 365 * 24 * 60 * 60 * 1000;
            window.location.href = '/admin';
          }
        } else {
          alert(this.$t('WRONG_COMBINATION'));
        }
      } catch (error) {
        alert(this.$t('WRONG_COMBINATION'));
      }
    },
  },
  async mounted() {
    this.setBreadLink(['Prime Radiant', 'LOGIN']);
  },
};
</script>
