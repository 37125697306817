var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"\n      table table-striped table-condensed table-bordered\n      datatable\n      tableStyle\n    "},[_c('thead',[_c('tr',[_c('th',{on:{"click":function($event){return _vm.setSort('flags.no_kyc')}}},[(!(_vm.isSortedBy('flags.no_kyc')))?_c('i',{staticClass:"fa fa-check-circle",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Respect de la compliance"}}):_c('i',{class:[ _vm.sorts['flags.no_kyc'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ]})]),_c('th',{on:{"click":function($event){return _vm.setSort('lastname')}}},[_vm._v(" "+_vm._s(_vm.$t('CLIENT'))+" "),(_vm.isSortedBy('lastname'))?_c('i',{class:[ _vm.sorts['lastname'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ],staticStyle:{"float":"right"}}):_vm._e()]),_c('th',{on:{"click":function($event){return _vm.setSort('created')}}},[_vm._v(" "+_vm._s(_vm.$t('SINCE'))+" "),(_vm.isSortedBy('created'))?_c('i',{class:[ _vm.sorts['created'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ],staticStyle:{"float":"right"}}):_vm._e()]),_c('th',{on:{"click":function($event){return _vm.setSort('n_contracts')}}},[_vm._v(" "+_vm._s(_vm.$t('WALLETS'))+" "),(_vm.isSortedBy('n_contracts'))?_c('i',{class:[ _vm.sorts['n_contracts'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ],staticStyle:{"float":"right"}}):_vm._e()]),_c('th',{on:{"click":function($event){return _vm.setSort('current')}}},[_vm._v(" "+_vm._s(_vm.$t('VALUE'))+" "),(_vm.isSortedBy('current'))?_c('i',{class:[ _vm.sorts['current'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ],staticStyle:{"float":"right"}}):_vm._e()]),_c('th',{on:{"click":function($event){return _vm.setSort('gains.percent')}}},[_vm._v(" "+_vm._s(_vm.$t('%_EARNINGS'))+" "),(_vm.isSortedBy('gains.percent'))?_c('i',{class:[ _vm.sorts['gains.percent'] == '1' ?
              'icon-arrow-up-circle' : 
              'icon-arrow-down-circle'
            ],staticStyle:{"float":"right"}}):_vm._e()])])]),_c('tbody',_vm._l((_vm.clientProps),function(item,index){return _c('tr',{key:index},[_c('td',[_c('button',{class:[ !item.flags.no_kyc ?
              'btn btn-link text-success': 
              'btn btn-link text-danger'
            ],attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-check-circle"})])]),_c('td',[(item.id)?_c('router-link',{attrs:{"to":'/dashboard/' + item.id}},[_vm._v(_vm._s(item.lastname)+" "+_vm._s(item.name))]):_c('router-link',{attrs:{"to":'/dashboard/' + item._id}},[_vm._v(_vm._s(item.lastname)+" "+_vm._s(item.name))])],1),_c('td',[_vm._v(_vm._s(_vm.dateFormat(item.created)))]),_c('td',[_vm._v(_vm._s(item.n_contracts))]),_c('td',[_vm._v(_vm._s(_vm.encoursFormat(Math.round(item.current)))+" €")]),_c('td',[_vm._v(_vm._s(Math.round(item.gains.percent * 100))+" %")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }