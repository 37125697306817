<template>
  <div class="row">
    <div class="col-12">
      <h5 style="top:10px;position: relative;">
        <i class="icon-grid" style="color:#36a9e1;margin-bottom: 20px;"></i>
        {{$t('DASHBOARD')}}
      </h5>
    </div>
    <div class="col-12">
      <nav_dash
        @clicked="chooseCompte"
        @revert="revertPf"
        @previewPf="previewPf"
        :showBtnRevert="showBtnRevert"
        :showBtnProposal="showBtnProposal"
      />
      <risk_dash :frameProps="client.riskframe" :riskProps="client.risk" />
    </div>
    <div class="col-12">
      <div class="tab-content" style="padding:15px; border: none !important">
        <template v-if="client.n_contracts == 1">
          <compte_dash
            :dataProps="client.individuals[0]"
            :totalCompteProps="totalCompte"
            :baseportfolioProps="client.baseportfolio_id"
          />
        </template>
        <template v-else>
          <compte_dash
            v-if="compte == -1"
            :dataProps="client.overall"
            :totalCompteProps="totalCompte"
            :baseportfolioProps="client.baseportfolio_id"
          />
          <compte_dash
            v-else
            :dataProps="client.individuals[compte]"
            :totalCompteProps="totalCompte"
            :baseportfolioProps="client.baseportfolio_id"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import Nav_Dash from "@/components/agent/dashboard/dash/nav.vue";
import Risk_Dash from "@/components/agent/dashboard/dash/risk.vue";
import Compte_Dash from "@/components/agent/dashboard/dash/compte.vue";

Vue.component("compte_dash", Compte_Dash);
Vue.component("risk_dash", Risk_Dash);
Vue.component("nav_dash", Nav_Dash);

export default {
  data() {
    return {
      compte: -1,
    };
  },
  props: {
    showBtnRevert: {
      type: Boolean,
      required: true,
    },
    showBtnProposal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({
      client: (state) => state.client_Portfolio,
    }),
    totalCompte() {
      let lastLength = this.client.overall.bt.length;
      return Math.round(this.client.overall.bt[lastLength - 1].value);
    },
  },
  methods: {
    chooseCompte(val) {
      this.compte = val;
    },
    revertPf() {
      this.$emit("revert");
    },
    previewPf() {
      this.$emit("previewPf", true);
    },
  },
};
</script>