<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style="text-align:center;">{{$t('RETIREMENT_PREPARATION')}}</h5>
              <button type="button" class="close" @click="hideModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12" style="font-size: 12px;text-align: left;">
                  <div class="card">
                    <div class="card-header" style="font-size:16px">
                      <strong>
                        <i class="fa fa-plus" style="margin-right: 10px;"></i>
                        {{$t('ADDITIONAL_DATA')}}
                      </strong>
                    </div>
                    <div class="card-body">
                      <form action method="post" class="form-horizontal">
                        <div class="form-group row">
                          <div class="col-md-12">
                            <div class="input-group">
                              <span class="input-group-addon">
                                <strong>{{$t('RETIREMENT_HORIZON')}}</strong>
                              </span>
                              <input
                                type="text"
                                id="txtRetireModalRetraite"
                                name="txtRetireModalRetraite"
                                class="form-control"
                                v-model.lazy="data.duree"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-md-12">
                            <div class="input-group">
                              <span class="input-group-addon">
                                <strong>{{$t('DESIRED_MONTHLY_PENSION')}}</strong>
                              </span>
                              <input
                                type="text"
                                id="txtAmountModalRetraite"
                                name="txtAmountModalRetraite"
                                class="form-control"
                                v-model.lazy="data.rent"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span @click="save()" class="btn btn-primary btn-sm myNavInfo next">{{$t('SAVE')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      data: {},
    };
  },
  props: {
    dataProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$emit("clicked", "retraite");
    },
    formatData() {
      let k = Object.keys(this.data);
      for (let index = 0; index < Object.keys(this.data).length; index++) {
        if (typeof this.data[k[index]] === "boolean") {
          continue;
        }
	  if(k[index] != "createdAt"){
	      this.data[k[index]] = this.data[k[index]]
		  .toString()
		  .replace(" ", "")
		  .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	  }
      }
    },
    save() {
	//this.formatData();
      this.$emit("saved", {
        name: "retraite",
        data: this.data,
      });
      this.$emit("clicked", "retraite");
    },
  },
  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style>
</style>
