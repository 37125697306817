<template>
  <div class="tab-pane active fade show">
    <label>
      <strong>
        <i class="fa fa-eur" /> 
        {{$t('SAVING')}}
      </strong>
    </label>
    <hr />
    <div class="card-body">
      <form action method="post" class="form-horizontal">
        <div class="form row mb-4">
          <div class="col-12 input-group">
            <span class="input-group-addon">
              {{$t('INITIAL')}} (€)
            </span>
            <BaseInputFormatted 
              class="form-control"
              v-model="data.apports.initial"
              :formatOutsideInput="formatOutsideInput"
              :formatInsideInput="formatInsideInput"
              :insideNewvalue="insideNewvalue"
              :outsideNewvalue="outsideNewvalue" 
            />
          </div>
        </div>
        <div class="form row mb-4">
          <div class="col-12 input-group">
            <label class="input-group-addon" for="married_checkbox">
              {{$t('MARRIED_CIVIL_PARTNERSHIP_JOINT_TAXATION')}}
            </label>
            <span class="checkBox__container">
              <input type="checkbox" id="married_checkbox" name="married_checkbox" />
            </span>
          </div>
        </div>
      </form>
    </div>
    <div v-if="data.others.length>1">
      <div class="card" v-for="(item,index) in data.others" :key="index">
        <div class="card-header">
          <strong>
            <i class="fa fa-plus" style="margin-right: 10px;"></i>
            <span>{{item.name}}</span>
          </strong>
        </div>
        <div class="card-body">
          <form action method="post" class="form-horizontal">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-eur"></i>
                  </span>
                  <input
                    type="text"
                    id="txtApports"
                    name="txtApports"
                    class="form-control"
                    placeholder="€"
                    :value="formatInt(item.value)"
                    readonly
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-center">
      <span
        class="btn btn-primary next"
        style="cursor:pointer;"
        @click="btnClick()"
      >{{$t('CONTINUE')}}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  components: {
    BaseInputFormatted
  },
  data() {
    return {
      immoApport: {
        price: 0,
        duree: 0,
        emprunt: 0,
      },
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.new_Compte,
    })
  },
  methods: {
    ...mapActions(["setNewCompte"]),

    btnClick() {
      this.$emit("clicked", {
        name: "Apports",
        next: "ESG",
      });
    },
    formatInt(x) {
      return Math.round(x)
        .toString()
        .replace(" ", "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  created() {
    this.cpt = 0;
    let ctx = this;
    this.$store.watch(
      function (state) {
        return state.new_Compte;
      },
      function (val) {
        ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
  },
  mounted(){
    this.initFormField()
  }
};
</script>

<style>
.checkBox__container {
  border: 1px solid lightgray;
  width: 40px;
  display: flex;
  justify-content: center;
}
</style>
