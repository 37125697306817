<template>
  <input :id="name" type="text" />
</template>
<script>
export default {
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    value: {
      required: true,
    },
    ticks: {
      type: Array,
      required: false,
      default: () => [],
    },
    tickspositions: {
      type: Array,
      required: false,
      default: () => [],
    },
    ticksLabels: {
      type: Array,
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    changeEventName: {
      type: String,
      required: false,
      default: 'slide-change',
    },
    reversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  methods: {
    renderSlider() {
      let ctx = this;
      let slider_element = document.getElementById(this.name);
      let options = {
        value: this.value,
        ticks: this.ticks,
        ticks_positions: this.tickspositions,
        ticks_labels: this.ticksLabels,
        min: this.min,
        max: this.max,
        step: this.step,
        tooltip: this.tooltip ? 'always' : 'hide',
        reversed: this.reversed,
        //id: this.name
      };

      this.slider = new this.$slider(slider_element, options);

      this.slider.on('change', (e) => {
        this.value = e.newValue;
        this.$emit('slided', { name: ctx.name, data: e.newValue });
      });

      if (this.name == 'sliderAversionRisk') {
        $('.slider-selection').css('background', '#40bf40');
        $('.slider-selection').css('opacity', 0.5);
        $('.slider-track-high').css('opacity', 0.5);
        $('.slider-track-high').css('background', '#990000');
      }
    },
  },
  mounted() {
    this.renderSlider();
  },
  beforeDestroy() {
    this.slider.destroy();
  },
  watch: {
    value: function(newValue) {
      let newSliderValue;

      if (newValue instanceof Array) {
        newSliderValue = [];
        newValue.forEach((val) => {
          let number = parseInt(val);
          if (isNaN(number)) {
            newSliderValue.push(val);
          } else {
            newSliderValue.push(number);
          }
        });
      } else {
        newSliderValue = newValue;
      }

      this.slider.setValue(newSliderValue);
    },
    min: function(val) {
      this.slider.options.min = val;
    },
    max: function(val) {
      this.slider.options.max = val;
    },
  },
};
</script>
