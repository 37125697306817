<template>
  <div>
    <NavDash
      @clicked="chooseCompte"
      :showBtnRevert="false"
      :showBtnUpdate="false"
      style="margin-bottom: 20px;"
      class="text-center"
    />
    <div v-if="showComparaison">
      <div v-if="selectedAccount !== ''" :style="{'justifyContent': hide_proposal && 'center'}" class="container">
        <div
          class="card w-100 align-self-start card_max-width"
        >
          <div class="card-header">
            <span class="font-weight-bold">
              <i class="fa fa-pie-chart" style="margin-right: 10px;"></i>
              {{ $t('CURRENT_VERSION') }}
            </span>
          </div>
          <div class="card-body">
            <div class="chart-wrapper">
              <PieChartDonut
                :widthProps="600"
                :heightProps="300"
                :dataProps="
                  distributedAccounts[selectedAccount].distributedAssests
                "
                :indexProps="0"
                :translateAssetsProps="translated"
                :translateUniversProps="universtranslated"
                :showThirdLayoutBtn="true"
                @checkedFunds="showFundsCurrent"
              />
            </div>
          </div>
          <div style="padding:20px">
            <template
              v-for="(item, name, index) in distributedAccounts[selectedAccount]
                .distributedAssests"
            >
              <div v-if="item.total > 0" :key="index">
                <div style="font-size: 16px;">
                  <span class="font-weight-bold">{{ translateUnivers(name) }}</span>
                  <span class="font-weight-bold float-right" id="spanFECO">
                    {{ Math.round(item.total * 100) }}
                  </span>
                </div>
                <div class="progress progress-sm mt-2 mb-3">
                  <div
                    class="progress-bar-inner"
                    :style="
                      'width:' +
                        Math.round(item.total * 100) +
                        '%;background-color: ' +
                        item.color
                    "
                    id="pbFECO"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </template>
          </div>
          <AssetsWeights
            v-if="!checkedFundsCurrent"
            :dataProps="distributedAccounts[selectedAccount].distributedAssests"
            :indexProps="0"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
          />
          <FundsWeights
            v-if="checkedFundsCurrent"
            :indexProps="0"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
            :dataProps="distributedAccounts[selectedAccount].distributedAssests"
          />
        </div>
        <div v-if="!hide_proposal"
          class="card w-100 align-self-start card_max-width"
        >
          <div class="card-header" style="background-color: #82d435;">
            <span class="font-weight-bold">
              <i class="fa fa-pie-chart" style="margin-right: 10px;"></i>
              {{$t('MANAGER_PROPOSAL')}}
            </span>
            <span v-if="basepf.optimization_date !== undefined">
              ({{ dateFormating(basepf.optimization_date) }})
            </span>
            <span v-else>
              ({{$t('NO_DATE_AVAILABLE')}})
            </span>
          </div>
          <div class="card-body">
            <div class="chart-wrapper">
              <PieChartDonut
                :widthProps="600"
                :heightProps="300"
                :dataProps="
                  distributedAccounts[selectedAccount].distributedPreviewAssests
                "
                :indexProps="0"
                :translateAssetsProps="translated"
                :translateUniversProps="universtranslated"
                :showThirdLayoutBtn="true"
                @checkedFunds="showFundsOld"
              />
            </div>
          </div>
          <div style="padding:20px">
            <template
              v-for="(item, name, index) in distributedAccounts[selectedAccount]
                .distributedPreviewAssests"
            >
              <div v-if="item.total > 0" :key="index">
                <div style="font-size: 16px;">
                  <strong>{{ translateUnivers(name) }}</strong>
                  <span class="font-weight-bold float-right" id="spanFECO">
                    {{ Math.round(item.total * 100) }}
                  </span>
                </div>
                <div class="progress progress-sm mt-2 mb-3">
                  <div
                    class="progress-bar-inner"
                    :style="
                      'width:' +
                        Math.round(item.total * 100) +
                        '%;background-color: ' +
                        item.color
                    "
                    id="pbFECO"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </template>
          </div>
          <AssetsWeights
            v-if="!checkedFundsOld"
            :dataProps="distributedAccounts[selectedAccount].distributedPreviewAssests"
            :indexProps="0"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
          />
          <FundsWeights
            v-if="checkedFundsOld"
            :indexProps="0"
            :translateAssetsProps="translated"
            :translateUniversProps="universtranslated"
            :dataProps="distributedAccounts[selectedAccount].distributedPreviewAssests"
          />
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('NO_COMPARAISON_AVAILABLE') }}
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import NavDash from '@/components/agent/dashboard/dash/nav.vue';
import PieChartDonut from '@/components/autre/donutchart.vue';
import AssetsWeights from '@/components/gerant/assets/assetsweights.vue';
import FundsWeights from '@/components/gerant/assets/fundsweights.vue';

export default {
  components: {
    PieChartDonut,
    NavDash,
    AssetsWeights,
    FundsWeights,
  },
  data() {
    return {
      distributedAccounts: {},
      selectedAccount: null,
      showComparaison: false,
      grpAssest: {},
      universtranslated: [],
      translated: [],
      checkedFundsCurrent: false,
      checkedFundsOld: false,
    };
  },
  props: {
    portfoliosProps: {
      type: Object,
      required: true,
      default: {},
    },
    basepf: {
      type: Object,
      required: true
    },

    hide_proposal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      client: (state) => state.client_Portfolio,
    }),
  },
  watch: {
    selectedAccount: function() {
      this.showComparaison = this.selectedAccount != null
    },
  },
  methods: {
    dateFormating(input) {
      let d = new Date(input);
      let months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let year = d.getFullYear();
      let month = months[d.getMonth()];
      let date = d.getDate();
      let hour = d.getHours();
      let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
      let time =
        date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return time;
    },
    chooseCompte(idx, name) {
      this.selectedAccount = name;
    },
    /***
     * Use grpAsset as reference to divid in univers a given list of asset
     */
    assetsDistribution(listAsset, listFundTmp) {
      let listFund = JSON.parse(JSON.stringify(listFundTmp));
      let distributedAssests = {};

      for (const [asset, assetVal] of Object.entries(listAsset)) {
        for (const univer of Object.keys(this.grpAssest)) {
          if (asset in this.grpAssest[univer].assets) {
            if (!(univer in distributedAssests)) {
              distributedAssests[univer] = {
                order: this.grpAssest[univer].order,
                assets: {},
                color: this.grpAssest[univer].color,
                total: [0],
              };
            }
            distributedAssests[univer].total[0] += assetVal;
            distributedAssests[univer].assets[asset] = {
              base_weights: [assetVal],
              funds: [],
            };

            for (const [isin, value] of Object.entries(listFund)) {
              let funds = this.grpAssest[univer].assets[asset].funds;
              funds.map((fund) => {
                if (isin == fund.ISIN) {
                  fund['weight'] = value;
                  distributedAssests[univer].assets[asset].funds.push({
                    Name: fund.Name,
                    ISIN: fund.ISIN,
                    weight: value,
                  });
                  delete listFund[isin];
                }
              });
            }

            break;
          }
        }
      }

      return distributedAssests;
    },
    accountManager() {
      let pathIndividual = this.portfoliosProps.individuals;
      let pathOverall = this.portfoliosProps.overall;

      if (this.client.n_contracts > 1) {
        this.distributedAccounts['consolidatedAccount'] = {
          distributedAssests: this.assetsDistribution(pathOverall.assetsDistribution, pathOverall.distribution),
          distributedPreviewAssests: this.assetsDistribution(pathOverall.preview_assetsDistribution, pathOverall.preview_distribution)
        };
      }

      for (const account of pathIndividual) {
        this.distributedAccounts[account.name] = {
          distributedAssests: this.assetsDistribution(account.assetsDistribution, account.distribution),
          distributedPreviewAssests: this.assetsDistribution(account.preview_assetsDistribution, account.preview_distribution)
        };
      }
      this.selectedAccount = Object.keys(this.distributedAccounts)[0];
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
      );
      this.grpAssest = data.data.resultat[0].contents;
      this.accountManager();
    },
    async getTranslatedUnivers() {
      let univers_translated = await this.$http.get(
        this.baseUrl + 'univers/translated'
      );
      this.universtranslated = univers_translated.data.resultat;
    },
    translateUnivers(n) {
      let idx = this.universtranslated.findIndex((e) => e.fr == n);
      return idx != -1 ? this.universtranslated[idx][this.$i18n.locale] : n;
    },
    async getTranslatedAssets() {
      let assets_translated = await this.$http.get(
        this.baseUrl + 'assets/translated'
      );
      this.translated = assets_translated.data.resultat;
    },
    showFundsCurrent(val) {
      this.checkedFundsCurrent = val;
    },
    showFundsOld(val) {
      this.checkedFundsOld = val;
    },
  },
  beforeMount() {
    this.getTranslatedUnivers();
    this.getTranslatedAssets();
    this.getGroupePf();
  },
};
</script>

<style scoped>
.container {
  margin-top: 10px;
  display: flex;
}
.container > div {
  margin-left: 5px;
  margin-right: 5px;
}
.card_max-width {
  max-width:750px;
}
@media only screen and (max-width: 1100px) {
  .container {
    flex-wrap: wrap;
  }
}
</style>
