<template>
    <div>
        <div class="d-flex justify-content-between">
            <h5 class="align-self-center m-0">
                <img src="../../../../../public/img/icons/planet-earth.png" class="icon" alt="earth and environment" />
                {{ $t('ESG') }}
            </h5>
            <div>
                <button
                    v-if="changeToESGGraph"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="preFillData()"
                >
                    {{ $t("DEFAULT_SETTINGS") }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="viewESGForm()"
                    :disabled="changeToESGGraph"
                >
                    {{ $t(getBtnText) }}
                </button>
                <button
                    v-if="showESGForm"
                    type="button"
                    class="btn btn-primary"
                    @click="saveESG()"
                >
                    {{ $t('SAVE') }}
                </button>
            </div>
        </div>
        <hr v-if="!showESGForm">
        <ESGForm v-if="showESGForm" :esgProps="client.esg" :showTitle="false" @changeESG="changeESG"/>
        <ESGGraph v-else :esgRes="client.esgResult" />
    </div>

</template>
<script>
    import { mapActions, mapState } from 'vuex';

    import ESGGraph from '@/components/agent/dashboard/resume/esgGraph.vue'
    import ESGForm from '@/components/agent/dashboard/resume/esgForm.vue'

    export default {
        data(){
            return {
                showESGForm: true,
            }
        },
        components: {
            ESGGraph,
            ESGForm 
        },
        props:{
            saved: {
                type: String,
                required: false
            }
        },
        watch: {
            saved: function(){
                this.init()
            }
        },
        computed: {
            ...mapState({
                baseUrl: (state) => state.baseUrl,
                client: (state) => state.client_Info,
                clientPF: (state) => state.client_Portfolio
            }),
            getBtnText(){
                return this.showESGForm ? 'VIEW_ESG_GRAPH' : 'VIEW_ESG_FORM'
            },
            changeToESGGraph(){
                return this.showESGForm && !this.clientHasESGResult()
            }
        },
        methods: {
            ...mapActions([
                'setClientInfo'
            ]),
            init(){
                if(this.clientHasESGResult()) this.showESGForm = false
            },
            clientHasESGResult(){
                if(!this.client.hasOwnProperty("esgResult")) return false
                else if(!Object.keys(this.client.esgResult).length) return false

                return true
            },
            setClientESG(data){
                let client = this.$_.cloneDeep(this.client)
                client.esg = data
                this.setClientInfo(client);
            },
            changeESG(esg){
                this.setClientESG(esg)
            },
            preFillData(){
                let data = {
                    introductionQuestion: {
                      itemsKeys: [
                        "environment_objective",
                        "social_objective",
                        "other_objective"
                      ],
                      itemsScores: [2, 3, 0]
                    },
                    esgGame: {
                      itemsWeights: [70,0,0,30,0],
                      itemsKeys: [
                        "esg.climate_change_adaptation_and_mitigation",
                        "esg.biodiversity",
                        "esg.pollution_and_water_resources_preservation",
                        "esg.circular_economy",
                        "esg.other"
                      ]
                    },
                    pai: []
                }
                this.setClientESG(data)
            },
            getSum(prev, curr) {
                return prev + Number(curr);
            },
            calcNumberOfTokenUsed(){
                return this.client.esg.introductionQuestion.itemsScores.reduce(this.getSum, 0)
            },
            calcESGGameWeights(){
                return this.client.esg.esgGame.itemsWeights.reduce(this.getSum, 0)
            },
            async saveESG(){
                if(this.calcNumberOfTokenUsed() !== 5) {
                    this.$emit("message", "TOKEN_DISTRIBUTION_ERROR")
                    return
                }
                else if(this.calcESGGameWeights() !== 100) {
                    this.$emit("message", "WEIGHT_DISTRIBUTION_ERROR")
                    return
                } 

                this.$emit("saveESG")
            },
            viewESGForm(){
                this.showESGForm = !this.showESGForm
            }
        },
        beforeMount(){
            this.init()
        }
    }
</script>