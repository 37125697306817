<template>
    <div>
        <div  v-for="item in Object.entries(esgRes)" 
            class="w-75 mt-2 mb-3 mr-auto ml-auto" :key="item[0]">
            <div style="font-size: 16px" :key="index">
                <span class="font-weight-bold">{{ $t('esg.'+item[0]) }}</span>
                <span class="font-weight-bold float-right">{{Math.round(item[1] * 100)}}</span>
            </div>
            <div class="progress">
            <div class="progress-bar" 
                role="progressbar" 
                :style="{'width': Math.round(item[1] * 100)+'%', 'background-color': esgColor[item[0]] ? esgColor[item[0]] : '#5585b5'}" 
                aria-valuenow="25" 
                aria-valuemin="0" 
                aria-valuemax="100"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                esgColor: {
                    biodiversity: "#66A156",
                    circular_economy: "#ffc93c",
                    climate_change_adaptation_and_mitigation: "#ff894c",
                    pollution_and_water_resources_preservation: "#79c2d0",
                    social: "#8594e4"
                }
            }
        },
        props: {
            esgRes: {
                type: Object,
                required: false,
                default: {}
            }
        }
    }
</script>