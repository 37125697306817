<template>
    <div class="tab-pane active fade show">
        <div class="well">
            <form action class="form-horizontal">
                <ESGForm :esgProps="data.infos.esg" :showTitle="true" @changeESG="changeESG"/>
            </form>
            <hr />
            <div class="text-center">
                <span
                    class="btn btn-primary next"
                    style="cursor:pointer;"
                    @click="btnClick()"
                >{{$t('CONTINUE')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'
import ESGForm from '@/components/agent/dashboard/resume/esgForm.vue'

export default {
    components: {
        BaseInputFormatted,
        ESGForm
    },
    data() {
        return {
            esgPaiList: [
                "greenhouse_gas_emission",
                "use_of_non_renewable_energy",
                "emissions_to_water",
                "human_rights",
                "social_and_employee_matters"
            ],
            numberOfEsgPrefToken: 5,
            formatInsideInput: /[^\d\.]/g,
            formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
            insideNewvalue: '',
            outsideNewvalue: '$1 '
        }
    },
    computed: {
        ...mapState({
            data: (state) => state.new_Compte,
        }),
        aggIsEqualToHundred(){
            let agg = this.data.infos.esg.esgGame.itemsWeights.reduce((prev, curr) => prev + Number(curr), 0)
            return agg === 100
        }
    },
    methods: {
        ...mapActions(["setNewCompte"]),
        btnClick() {
            this.$emit("clicked", {
                name: "ESG",
                next: "KYC",
            });
        },
        setEsgPrefToken(e, index, item){
            this.data.infos.esg.introductionQuestion.itemsScores[index] = e.target.value
            let distributedToken = this.data.infos.esg.introductionQuestion.itemsScores.reduce((prev, curr) => prev + Number(curr), 0)

            if(distributedToken > this.numberOfEsgPrefToken) {
                e.target.value = item
                this.data.infos.esg.introductionQuestion.itemsScores[index] = item
            }
        },
        addItem(event){
            if(!this.data.infos.esg.pai.includes(event.target.value)) this.data.infos.esg.pai.push(event.target.value)
        },
        removePai(idx){
            this.data.infos.esg.pai.splice(idx, 1);
        },
        changeESG(esg){
            this.data.infos.esg = esg
        }
    },
    created() {
        let ctx = this;
        this.$store.watch(
        function (state) {
            return state.new_Compte;
        },
        function (val) {
            ctx.setNewCompte(val);
        },
        {
            deep: true,
        }
        );
    }
};
</script>

<style>
.row__container{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.pai__container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px;
}
.pai{
    border: 1px solid black;
    width: fit-content;
    padding: 5px;
}
</style>
