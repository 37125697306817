<template>
  <div>
    <transition name="fade">
      <Spinner v-show="showSpinner" />
    </transition>
    <notifications
      classe="n-light"
      group="foo"
      position="top center"
      :max="3"
      :width="400"
    />

    <h3 class="text-center">{{ $t('OPTIMIZE_PORTFOLIOS') }}</h3>
    <br />
    <div class="row col-12">
      <div class="col-6 tableContainer">
        <AssetsVue
          @isShow="isShow"
          @clicked="updateAssets"
          :dataProps="allAssets"
          :translateAssetsProps="translated"
          :translateUniversProps="universtranslated"
        />
      </div>
      <div class="col-6 tableContainer">
        <ResumeVue :dataProps="allAssets" :translateAssetsProps="translated" />
      </div>
    </div>
    <br />
    <div class="col-12 text-center">
      <button
        class="btn btn-primary myNavInfo"
        @click="startOptimisation()"
      >
        {{ $t('PERFORM_OPTIMIZATION') }}
      </button>
    </div>
    <br />
    <OptimisationComponents
      v-if="optimizationIsDone"
      :optimisationProps="optimisationResult"
      :fundsProps="fundsDistribution"
      @savingPortfolios="savePortfolios"
      :assetsTranslatedProps="translated"
      :universTranslatedProps="universtranslated"
      :groupePfProps="groupePf"
      :pfDetailsProps="pfDetails"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
import { mapActions, mapState } from 'vuex';

import AssetsVue from '@/components/gerant/assets/assetsVue.vue';
import ResumeVue from '@/components/gerant/assets/resumeVue.vue';
import SliderAR from '@/components/autre/slider.vue';
import Spinner from '@/components/autre/spinner.vue';
import OptimisationComponents from '@/components/gerant/main/optimisation.vue';

export default {
  data() {
    return {
      groupePf: {},
      pfNotes: [],
      pfDetails: [],
      allAssets: [],
      commentaire: '',
      oneIsShow: false,
      optimizationIsDone: false,
      showSpinner: false,
      optimisationResult: [],
      fundsDistribution: [],
      universtranslated: [],
      translated: [],
    };
  },
  components: {
    Spinner,
    SliderAR,
    ResumeVue,
    AssetsVue,
    OptimisationComponents,
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
    }),
  },
  methods: {
    ...mapActions(['setBreadLink', 'setLogged']),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({
          isLog: false,
          role: 0,
        });
        await this.$http.get(this.baseUrl + 'auth/logout');
        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role == 20) {
          //do something here
          this.getAllAssets();
        } else {
          this.setLogged({
            isLog: false,
            role: 0,
          });
          await this.$http.get(this.baseUrl + 'auth/logout');
          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    async getAllAssets() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
      );

      let tmp = {};

      for (const iterator of Object.entries(data.data.resultat[0].contents)) {
        for (const it of Object.entries(iterator[1].assets)) {
          tmp[it[0]] = 0;
        }
      }
      this.allAssets = tmp;
    },
    isShow(value) {
      this.oneIsShow = value;
    },
    formatOptimisationResult() {
      let tempOptim = this.optimisationResult;
      for (let pf of tempOptim) {
        // pf[0] => reference, pf[1] => Optimized, pf[2] => Adjusted
        for (let asset of Object.entries(pf[0])) {
          if (!(asset[0] in pf[1])) {
            pf[1][asset[0]] = 0;
            pf[2][asset[0]] = 0;
          }
        }
      }
      this.optimisationResult = Object.assign([], this.optimisationResult, tempOptim);
    },
    async startOptimisation() {
      let ctx = this;
      this.showSpinner = true;
      let data = await this.$http.post(
        this.baseUrl + 'cunit/optimisation',
        {
          company: localStorage.getItem('priradCompany'),
          views: ctx.allAssets,
        },
        {
          headers: {
            function: 'Optimisation',
          },
        }
      );

      if (data.status == 200) {
        this.optimisationResult = data.data.result;
        this.formatOptimisationResult()
        this.fundsDistribution = data.data.fundsDistribution;
        this.optimizationIsDone = true;
        this.showSpinner = false;
      } else {
        let title = this.$t('OPTIMIZATION');
        let group = 'foo';
        let type = 'error';
        let text = this.$t('Error');
        this.$notify({
          group,
          title,
          text,
          type,
        });
      }
    },
    updateAssets({value, name}) {
      this.allAssets[name] = value;
    },
    async savePortfolios(saved) {

      let title = this.$t('BACKING_UP_PORTFOLIOS');
      let group = 'foo';
      let type = '';
      let text = this.$t('PERSONALIZED_PORTFOLIOS_SAVED');
      
      if(!saved) {
        type = 'error';
        text = this.$t('ERROR_OCCURRED_TRY_AGAIN');
      }else{
        await this.getGroupePf()
      }

      this.$notify({
        group,
        title,
        text,
        type,
      });
    },
    async getGroupePf() {
      let data = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany')
      );
      const {portfolio_names, portfolio_notes, contents} = data.data.resultat[0]

      this.groupePf = contents;

      this.pfDetails = portfolio_names.map((pfName, index) => {
        return {index, pfName, pfNotes: portfolio_notes[index]}
      })
    },
    async getTranslatedUnivers() {
      let univers_translated = await this.$http.get(
        this.baseUrl + 'univers/translated'
      );
      this.universtranslated = univers_translated.data.resultat;
    },
    async getTranslatedAssets() {
      let assets_translated = await this.$http.get(
        this.baseUrl + 'assets/translated'
      );
      this.translated = assets_translated.data.resultat;
    },
  },
  mounted() {
    this.checkLog();
    this.setBreadLink(['Prime Radiant', 'MANAGER', 'OPTIMIZATION_PORTFORLIOS']);
    this.getGroupePf();
    this.getTranslatedAssets();
    this.getTranslatedUnivers();
  },
};
</script>

<style scoped>

.tableContainer {
  max-height: 500px;
  overflow-y: auto; 
}
.notification.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  color: #495061;
  background: #eaf4fe;
  border: 1px solid #d4e8fd;
}

.notification-title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  color: #eaf4fe;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn{
	border: none;
	width: fit-content;
	padding: 10px 20px;
}
</style>
