<template>
    <div class="tab-pane active fade show">
        <div class="well">
            <label class="d-flex justify-content-between">
                <strong>
                    <i class="fa fa-file" />
                    {{$t('KYC')}}
                </strong>
                <div v-if="data.note !== null" >
                    <span class="font-weight-bold">{{$t('RISK_LEVEL')}} </span>
                    <span>{{data.note.toFixed(1)}}</span>
                </div>
            </label>
            <hr />
            <div>
                <div class="card-body">
                    <form action method="post" class="form-horizontal">
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('DURATION')}}</span>
                                <input
                                    type="text"
                                    id="_DURATION"
                                    name="_DURATION"
                                    class="form-control"
                                    placeholder
                                    v-model="data.infos.account.duration"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_VOLATILITY')}}</span>
                                <input
                                    type="text"
                                    id="_ANNUAL_VOLATILITY"
                                    name="_ANNUAL_VOLATILITY"
                                    class="form-control"
                                    placeholder
                                    v-model="data.newKYC.annualVolatility"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('LIQUID_ASSETS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.liquidAssets"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('FINANCIAL_INVESTMENTS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.financialInvestments"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('TOTAL_CURRENT_FUTURE_FINANCIAL_COMMITMENTS')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.debt"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('OTHER_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.other"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_INCOME_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.annualIncome"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ANNUAL_INCOMPRESSIBLE_SPENDING')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.newKYC.incompressibleSpending"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>

                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('ESTIMATED_ANNUAL_CONTRIBUTION')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.infos.account.estimatedAnnualContribution"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                        </div>

                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('REAL_ESTATE_MAJ')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.infos.financialProfile.realEstate"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('INITIAL_INVESTMENT_AMOUNT')}}</span>
                                <BaseInputFormatted
                                    class="form-control"
                                    v-model="data.apports.initial"
                                    :formatOutsideInput="formatOutsideInput"
                                    :formatInsideInput="formatInsideInput"
                                    :insideNewvalue="insideNewvalue"
                                    :outsideNewvalue="outsideNewvalue"
                               />
                            </div>
                        </div>

                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('TIME_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_TIME_CONSISTENCY"
                                    name="_TIME_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="data.infos.personnalRiskProfile.timeConsistency"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('AGE_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_AGE_CONSISTENCY"
                                    name="_AGE_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="data.infos.personnalRiskProfile.ageConsistency"
                                />
                            </div>
                        </div>
                        <div class="form-row mb-4">
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('INVESTMENT_OBJECTIVE_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_INVESTMENT_OBJECTIVE_CONSISTENCY"
                                    name="_INVESTMENT_OBJECTIVE_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="data.infos.personnalRiskProfile.investmentObjectiveConsistency"
                                />
                            </div>
                            <div class="col input-group">
                                <span class="input-group-addon">{{$t('CAPACITY_CONSISTENCY')}}</span>
                                <input
                                    type="text"
                                    id="_CAPACITY_CONSISTENCY"
                                    name="_CAPACITY_CONSISTENCY"
                                    class="form-control"
                                    placeholder
                                    v-model="data.infos.personnalRiskProfile.capacityConsistency"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div class="text-center">
                <span
                    class="btn btn-primary next mr-2"
                    style="cursor:pointer;"
                    @click="getNote()"
                >{{$t('VALIDATION')}}</span>
                <span
                    v-if="data.note !== null"
                    class="btn btn-primary next"
                    style="cursor:pointer;"
                    @click="btnClick()"
                >{{$t('CONTINUE')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
    components: {
        BaseInputFormatted
    },
    data() {
        return {
            esgPaiList: [
                "greenhouse_gas_emission",
                "use_of_non_renewable_energy",
                "emissions_to_water",
                "human_rights",
                "social_and_employee_matters"
            ],
            numberOfEsgPrefToken: 5,
            formatInsideInput: /[^\d\.]/g,
            formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
            insideNewvalue: '',
            outsideNewvalue: '$1 '
        }
    },
    computed: {
        ...mapState({
            data: (state) => state.new_Compte,
            baseUrl: (state) => state.baseUrl,
        }),
        aggIsEqualToHundred(){
            let agg = this.data.infos.esg.esgGame.itemsWeights.reduce((prev, curr) => prev + Number(curr), 0)
            return agg === 100
        }
    },
    methods: {
        ...mapActions(["setNewCompte"]),
        btnClick() {
            this.$emit("clicked", {
                name: "KYC",
                next: "Contr",
            });
        },
        async getNote(){
            try{
                let call = await this.$http.post(
                    this.baseUrl + "cunit/generali_kyc",
                    {
                        "annualVolatility": this.data.newKYC.annualVolatility/100,
                        "RealEstate" : Number(this.data.infos.financialProfile.realEstate),
                        "LiquidAssets" : Number(this.data.newKYC.liquidAssets),
                        "FinancialInvestments": Number(this.data.newKYC.financialInvestments),
                        "Other" : Number(this.data.newKYC.other),
                        "Debt" :  Number(this.data.newKYC.debt), //Total Current and Future Financial Commitments
                        "Income" :  Number(this.data.newKYC.annualIncome),
                        "IncompressibleSpending" : Number(this.data.newKYC.incompressibleSpending),
                        "InitialInvestment": Number(this.data.apports.initial),
                        "EstimatedContribution": Number(this.data.infos.account.estimatedAnnualContribution),
                        "age":Number(this.data.infos.personnalRiskProfile.ageConsistency),
                        "time":Number(this.data.infos.personnalRiskProfile.timeConsistency),
                        "investmentObjectives": Number(this.data.infos.personnalRiskProfile.investmentObjectiveConsistency),
                        "lossCapacity": Number(this.data.infos.personnalRiskProfile.capacityConsistency)
                    }
                );
                if(call.data.vol){
                    this.data.kyc_vol = call.data.vol

                    call = await this.$http.post(
                        this.baseUrl + "cunit/vol2Note",
                        {
                            vol: call.data.vol
                        }
                    )
                    this.data.note = call.data.note
                }
            }catch(err){}
        }
    },
    created() {
        let ctx = this;
        this.$store.watch(
        function (state) {
            return state.new_Compte;
        },
        function (val) {
            ctx.setNewCompte(val);
        },
        {
            deep: true,
        }
        );
    }
};
</script>

<style>
.row__container{
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.pai__container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px;
}
.pai{
    border: 1px solid black;
    width: fit-content;
    padding: 5px;
}
</style>
