<template>
  <svg :id="guid" ref="barSimulation" />
</template>

<script>
import * as d3 from "d3";

export default {
    name: "BarSimulation",
    props: {
	proba: Number,
	cible: String,
	cibleInt: Number,
	annee: Number,
	name: String,
	gen: String,
	quantfront: Array
    },
    watch: {
	gen: function() {
	    document.getElementById(this.guid).innerHTML = "";
	    this.barInit();
	}
    },
    data() {
	return {
	    width: 150,
	    height: 450,
	    barWidth: 25,
	    barHeight: 450,
	    margin: { top: 20, left: 50, bottom: 20, right: 20 },
	    yScaleDomain: { min: 0, max: 100 },
	    numberOfTicks: 4,
	    ticksVal: [5, 20, 50, 80, 95],
	    ticksValSimplify: [50],
	    ticksLabelSimplify: ["50%"],
	    ticksLabel:["95%","80%","50%","20%","5%"],
	    yAxisTranslate: { x: 10, y: 0 },
	    guid: ""
	};
    },
    methods: {
	formatNumber(number) {
	    return Math.round(number)
		.toString()
		.replace(" ", "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	},
	guidGenerator() {
	    let S4 = function() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	    };
	    return (
		S4() +
		    S4() +
		    "-" +
		    S4() +
		    "-" +
		    S4() +
		    "-" +
		    S4() +
		    "-" +
		    S4() +
		    S4() +
		    S4()
	    );
	},
	
	barInit() {
	    const innerWidth = this.width - this.margin.left - this.margin.right;
	    const innerHeight = this.height - this.margin.top - this.margin.bottom;
	    
	    const svg = d3
		  .select(this.$refs.barSimulation)
		  .attr("width", this.width)
		  .attr("height", this.height);
	    
	    const grp = svg
		  .append("g")
		  .attr(
		      "transform",
		      `translate(${this.margin.left}, ${this.margin.top})`
		  );
	    
	    const linearGradient = grp
		  .append("linearGradient")
		  .attr("id", "linear-gradient")
		  .attr("x1", "0%")
		  .attr("y1", "0%")
		  .attr("x2", "0%")
		  .attr("y2", "100%");
	    
	    linearGradient
		.selectAll("stop")
		.data([
		    { offset: "0%", color: "green" },
		    { offset: "25%", color: "steelBlue" },
		    { offset: "75%", color: "orange" },
		    { offset: "100%", color: "purple" }
		])
		.enter()
		.append("stop")
		.attr("offset", function(d) {
		    return d.offset;
		})
		.attr("stop-color", function(d) {
		    return d.color;
		});
	    
	    const bar = grp
		  .append("rect")
		  .attr("width", this.barWidth)
		  .attr("height", innerHeight)
		  .style("fill", "url(#linear-gradient)");
	    
	    const yScale = d3
		  .scaleLinear()
		  .domain([this.yScaleDomain.min, this.yScaleDomain.max])
		  .range([innerHeight, 0]);
	    
	    const yAxisGenerator = d3
		  .axisLeft(yScale)
		  .ticks(this.numberOfTicks)
		  .tickValues(this.ticksVal)
		  .tickFormat((d, i) => this.ticksLabel[i]);
	    
	    const yAxisGeneratorSimplify = d3
		  .axisLeft(yScale)
		  .ticks(this.numberOfTicks)
		  .tickValues(this.ticksValSimplify)
		  .tickFormat((d, i) => this.ticksLabelSimplify[i]);
	    
	    let ticksMoneyLabel = [];
	    
	    for (let iterator of this.quantfront) {
		let tmp = this.$formatNumberKM(iterator);
		ticksMoneyLabel.push(tmp + "€");
	    }
	    
	    const yScale2 = d3
		  .scaleLinear()
		  .domain([this.yScaleDomain.min, this.yScaleDomain.max])
		  .range([innerHeight, 0]);
	    
	    const yAxisGenerator2 = d3
		  .axisRight(yScale2)
		  .ticks(this.numberOfTicks)
		  .tickValues(this.ticksVal)
		  .tickFormat((d, i) => ticksMoneyLabel[i]);
	    
	    const yAxisGenerator2Simplify = d3
		  .axisRight(yScale2)
		  .ticks(this.numberOfTicks)
		  .tickValues(this.ticksValSimplify)
		  .tickFormat(d => ticksMoneyLabel[2]);
	    
	    const yAxis = grp.append("g").call(yAxisGenerator);
	    const yAxisSimplify = grp.append("g").call(yAxisGeneratorSimplify);
	    
	    const leftTick = yAxis.selectAll(".tick").style("visibility", "hidden").style("font-size",15)
	    const leftTickSimplify = yAxisSimplify
		  .selectAll(".tick")
		  .style("visibility", "visible")
		  .style("font-size",15)
	    
	    const yAxis2 = grp
		  .append("g")
		  .call(yAxisGenerator2)
		  .attr("transform", "translate(25, 0)")
		  .attr("font-size",15)
	    
	    const yAxis2Simplify = grp
		  .append("g")
		  .call(yAxisGenerator2Simplify)
		  .attr("transform", "translate(25, 0)")
		  .attr("font-size",15)
	    
	    const rightTicks = yAxis2
		  .selectAll(".tick")
		  .style("visibility", "hidden")
		  .attr("font-size",15)
	    
	    const rightTicksSimplify = yAxis2Simplify
		  .selectAll(".tick")
		  .style("visibility", "visible")
		  .attr("font-size",15)
	    
	    bar
		.on("mouseover", () => {
		    rightTicks.style("visibility", "visible");
		    rightTicksSimplify.style("visibility", "hidden");
		    leftTick.style("visibility", "visible");
		    leftTickSimplify.style("visibility", "hidden");
		})
		.on("mouseout", () => {
		    rightTicks.style("visibility", "hidden");
		    rightTicksSimplify.style("visibility", "visible");
		    leftTick.style("visibility", "hidden");
		    leftTickSimplify.style("visibility", "visible");
		});
	    
	    let inverProba = 100 - this.proba;
	    
	    yAxis
		.append("line")
		.attr("x1", 0)
		.attr("x2", this.barWidth)
		.attr("y1", () => yScale(inverProba))
		.attr("y2", () => yScale(inverProba))
		.attr("stroke-width", 4)
		.attr("stroke", "black")
		.attr("stroke-dasharray", "10");
	    
	    yAxis
		.append("text")
		.attr("fill", "black")
		.attr("font-weight", 700)
		.attr("font-size",15)
		.text(this.proba + "%")
		.attr("x", this.barWidth - 30)
		.attr("y", () => yScale(inverProba));
	    
	    
	    yAxis2
		.append("text")
		.attr("fill", "black")
		.attr("font-weight", 700)
		.attr("font-size",15)
		.text(this.$formatNumberKM(this.cibleInt) + "€")
		.attr("x", this.barWidth - 20)
		.attr("y", () => yScale(inverProba));
	}
    },
    created() {
	this.guid = this.guidGenerator();
    },
    mounted() {
	this.barInit();
    }
};
</script>

<style>
</style>
