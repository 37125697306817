import everpolate from '@/assets/js/graph/everpolate.min.js';

let global_langfile = null;

function formatInt(x) {
  let nb = Math.ceil(x / 100) * 100;
  return nb
    .toString()
    .replace(' ', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function formatNumberKM(number) {
    let deli = global_langfile == "/js/fr-FR.json" ? "." : ",";

  let SI_SYMBOL = ['', ' K', ' M', ' G', ' T', ' P', ' E'];
  // what tier? (determines SI symbol)
  let tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  let suffix = SI_SYMBOL[tier];
  let scale = Math.pow(10, tier * 3);

  // scale the number
  let scaled = number / scale;

  // format number and add suffix
  return number < 10000
    ? formatInt(number) + ' '
    : suffix === ' K'
    ? scaled.toFixed(0).replace(/\./g, deli) + suffix
    : scaled.toFixed(1).replace(/\./g, deli) + suffix;
}

//Constructor
export default function ExpectationsGraph(rootdiv, langfile) {
  this.langfile = typeof langfile !== 'undefined' ? langfile : '';
  this.rootdiv = rootdiv;
  this.graphdiv = '.gdiv';
  this.$priority = {};

  $(rootdiv).addClass('expgraph');
  $(rootdiv).append('<div class="ginfo"></div>');
  $(rootdiv).append('<div class="ginfo2"></div>');
  $(rootdiv).append('<div class="gdiv"></div>');
  $(rootdiv).append('<p id="labelObjective"></p>');

  this.fdata = {
    cashin: null,
  }; // full data
  if (this.langfile != '') this.setLocales(langfile);

  this.Configuration();
  this.FirstRender();
    this.RenderLocale();
    this.urlquery = '/api/';
}

ExpectationsGraph.prototype.setLocales = function(langfile) {
    global_langfile = langfile;
  let thisParent = this;
  thisParent.locale = {};
    d3.json(langfile, function(error, locale) {
	thisParent.locale = locale;
	thisParent.RenderLocale();
	thisParent.ComputeProjects;
  });
};

ExpectationsGraph.prototype.RenderLocale = function() {
  this.RenderTable();

  this.f1.g.select('.axis-title').text(this.locale.money);
  $(this.rootdiv)
    .find('#labelObjective')
    .html(this.locale.labelObjective);
};

ExpectationsGraph.prototype.RenderTable = function() {
  var raw_table =
    '<table>\
    <tr style="border-bottom:1pt solid #CCC;">\
        <th colspan="2" id="year" class="text-center" style="font-size:1.1em;"></th>\
    </tr>\
    <tr>\
        <td>\
<span class="refLine">&nbsp;&nbsp;</span>&nbsp;' +
    this.locale.acc_deposits +
    '</td>\
        <td class="text-right">\
            <span id="accDeposits"></span>&nbsp;€</td>\
    </tr>\
\
    <tr class="g_posteriors">\
<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
    this.locale.median_result +
    '</td>\
        <td class="text-right">\
            <span id="Q50"></span>€</td>\
    </tr>\
    <tr class="g_posteriors" style="border-bottom:1pt solid #CCC;">\
<th colspan="2" class="text-center" style="font-weight:bold;font-size30:1em;">' +
    this.locale.scenario +
    '</th>\
    </tr>\
    <tr class="g_posteriors">\
        <td>\
<span class="refcolor" id="AMUpSpan">&nbsp;&nbsp;</span>&nbsp;' +
    this.locale.optimistic +
    '\
            <span id="l34"></span>%</td>\
        <td class="text-right">\
            <span class="l3"></span>€ -\
            <span class="l4"></span>€</td>\
    </tr>\
    <tr class="g_posteriors">\
        <td>\
<span class="refcolor" id="AMMidSpan">&nbsp;&nbsp;</span>&nbsp;' +
    this.locale.normal +
    '\
            <span id="l23"></span>%</td>\
        <td class="text-right">\
            <span class="l2"></span>€ -\
            <span class="l3"></span>€</td>\
    </tr>\
    <tr class="g_posteriors">\
        <td>\
<span class="refcolor" id="AMLowSpan">&nbsp;&nbsp;</span>&nbsp;' +
    this.locale.pessimistic +
    '\
            <span id="l12"></span>%</td>\
        <td class="text-right">\
            <span class="l1"></span>€ -\
            <span class="l2"></span>€</td>\
    </tr>\
</table>';
  $(this.rootdiv)
    .find('.ginfo')
    .html(raw_table);
};

ExpectationsGraph.prototype.RenderTable2 = function() {
  let raw_table = `<table id="table_projets">
</table>`;

  $(this.rootdiv)
    .find('.ginfo2')
    .html(raw_table);
};

ExpectationsGraph.prototype.Configuration = function() {
  this.firstCall = true;
  this.parseTime = d3.timeParse('%Y');
  this.bisectDate = d3.bisector(function(d) {
    return d.date;
  }).left;

  let awidth = 750,
    aheight = 422; // aspect ratio of 16:9
  let f1 = {};

  f1.margin = {
    top: 0,
    right: 20,
    bottom: 30,
    left: 40,
  };

  (f1.width = awidth - f1.margin.left - f1.margin.right),
    (f1.height = aheight - f1.margin.top - f1.margin.bottom);

  f1.svg = d3
    .select(this.rootdiv)
    .select(this.graphdiv)
    .classed('svg-container', true) //container class to make it responsive
    .append('svg')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('viewBox', '0 0 ' + awidth + ' ' + aheight)
    .classed('svg-content-responsive', true);

  f1.xaxis = d3.scaleTime().range([0, f1.width]);
  f1.yaxis = d3.scaleLinear().range([f1.height, 0]);
  f1.Dxaxis = d3.axisBottom(f1.xaxis);

  let thisParent = this;

  f1.Dyaxis = d3
    .axisLeft(f1.yaxis)
    .ticks(6)
    .tickFormat(function(d) {
      let cashin = thisParent.fdata.cashin;

      let diff =
        d3.max(cashin, function(d) {
          return d.value;
        }) -
        d3.min(cashin, function(d) {
          return d.value;
        });
      if (typeof thisParent.current == 'undefined') {
        return Math.abs(diff) >= 5000 ? parseInt(d / 1000) + 'K' : d;
      } else {
        var idx = cashin.length - 1;

        var d_ = thisParent.current.post[idx],
          tmp = Math.abs(d_['l4'] - d_['l1']);
        return Math.abs(tmp) >= 5000 ? parseInt(d / 1000) + 'K' : d;
      }
    });

  // add lines and curves
  f1.lines = {};
  f1.lines.cashin = d3
    .line()
    .x(function(d) {
      return f1.xaxis(d.date);
    })
    .y(function(d) {
      return f1.yaxis(d.value);
    });
  f1.areas = {};
  let curveAreaType = d3.curveLinear; //d3.curveBasis;
  f1.areas.up = d3
    .area()
    .curve(curveAreaType)
    .x(function(d) {
      return f1.xaxis(d.date);
    })
    .y1(function(d) {
      return f1.yaxis(d.l4);
    })
    .y0(function(d) {
      return f1.yaxis(d.l3);
    });
  f1.areas.mid = d3
    .area()
    .curve(curveAreaType)
    .x(function(d) {
      return f1.xaxis(d.date);
    })
    .y1(function(d) {
      return f1.yaxis(d.l3);
    })
    .y0(function(d) {
      return f1.yaxis(d.l2);
    });
  f1.areas.low = d3
    .area()
    .curve(curveAreaType)
    .x(function(d) {
      return f1.xaxis(d.date);
    })
    .y1(function(d) {
      return f1.yaxis(d.l2);
    })
    .y0(function(d) {
      return f1.yaxis(d.l1);
    });
  f1.g = f1.svg
    .append('g')
    .attr(
      'transform',
      'translate(' + f1.margin.left + ',' + f1.margin.top + ')'
    );

  this.f1 = f1;
};

ExpectationsGraph.prototype.FirstRender = function() {
  let thisParent = this;
  let rootdiv = this.rootdiv;

  let f1 = this.f1;
  let today = new Date(),
    begin = today.getFullYear() - 1, // -1 is to get also the present year
    end = begin + 10 + 1; // 10 years

  begin = new Date(begin.toString());
  end = new Date(end.toString());
  let array_dates = d3.timeYears(begin, end);

  let data = array_dates.map(function(d) {
    return {
      date: d,
      value: 0,
    };
  });

  this.fdata.cashin = data;

  f1.xaxis.domain(
    d3.extent(data, function(d) {
      return d.date;
    })
  );
  f1.yaxis.domain([
    d3.min(data, function(d) {
      return d.value;
    }) / 1.005,
    d3.max(data, function(d) {
      return d.value;
    }) * 1.005,
  ]);

  f1.g
    .append('rect')
    .attr('class', 'border')
    .attr('width', f1.width)
    .attr('height', f1.height);

  f1.g
    .append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(0,' + f1.height + ')')
    .call(f1.Dxaxis);

  f1.g
    .append('g')
    .attr('class', 'axis axis--y')
    .call(f1.Dyaxis)
    .append('text')
    .attr('class', 'axis-title')
    .attr('transform', 'rotate(-90)')
    .attr('y', 6)
    .attr('dy', '.71em')
    .attr('x', -6)
    .style('text-anchor', 'end')
    .attr('fill', '#5D6971')
    .text(this.locale.money);

  let txtinfo = $(rootdiv).find('.ginfo');
  let txtinfo2 = $(rootdiv).find('.ginfo2');

  f1.g
    .append('path')
    .datum(data)
    .attr('class', 'cashin-line')
    .attr('d', f1.lines.cashin);

  f1.ending = f1.g.append('g').attr('class', 'ending');
  f1.ending
    .append('rect')
    .attr('class', 'end-area')
    .attr('x', f1.xaxis(data[data.length - 1].date))
    .attr('y', 0)
    .attr('width', f1.width - f1.xaxis(data[data.length - 1].date))
    .attr('height', f1.height);
  f1.ending
    .append('line')
    .attr('class', 'end-line')
    .attr('x1', f1.xaxis(data[data.length - 1].date))
    .attr('x2', f1.xaxis(data[data.length - 1].date))
    .attr('y1', 0)
    .attr('y2', f1.height);
  f1.ending
    .append('line')
    .attr('class', 'target-line target-fade')
    .attr('x1', 0)
    .attr('x2', f1.width)
    .attr('y1', -100)
    .attr('y2', -100);
  f1.ending
    .append('text')
    .attr('class', 'target-title target-fade')
    .attr('y', -100)
    .attr('dy', '.71em')
    .attr('x', 25)
    .attr('fill', '#5D6971')
    .text('text-to-fill');

  f1.ending
    .append('circle')
    .attr('class', 'target-circle target-fade')
    .attr('cx', f1.xaxis(data[data.length - 1].date))
    .attr('cy', -100)
    .attr('r', 15);

  let focus = f1.g
    .append('g')
    .attr('class', 'focus')
    .style('display', 'none');

  focus
    .append('line')
    .attr('class', 'x-hover-line hover-line')
    .attr('y1', 0)
    .attr('y2', f1.height);

  focus
    .append('line')
    .attr('class', 'y-hover-line hover-line')
    .attr('x1', f1.width)
    .attr('x2', f1.width);

  focus.append('circle').attr('r', 7.5);

  focus
    .append('text')
    .attr('x', 15)
    .attr('dy', '.31em');

  f1.svg
    .append('rect')
    .attr(
      'transform',
      'translate(' + f1.margin.left + ',' + f1.margin.top + ')'
    )
    .attr('class', 'overlay')
    .attr('width', f1.width)
    .attr('height', f1.height)
    .on('mouseover', function() {
      focus.style('display', null);
      txtinfo.show();
      txtinfo2.show();
    })
    .on('mouseout', function() {
      focus.style('display', 'none');
      txtinfo.hide();
      txtinfo2.hide();
    })
    .on('click', mouseclick)
    .on('mousemove', mousemove);

  function mouseclick() {}
  function mousemove() {
    let data = thisParent.fdata.cashin;

    let x0 = f1.xaxis.invert(d3.mouse(this)[0]),
      i = thisParent.bisectDate(data, x0, 1),
      d0 = data[i - 1],
      d1 = data[i],
      d = x0 - d0.date > d1.date - x0 ? d1 : d0; // TODO: because data.cashin is out of range
    // new i
    i = x0 - d0.date > d1.date - x0 ? i : i - 1;

    // plot line
    focus.attr(
      'transform',
      'translate(' + f1.xaxis(d.date) + ',' + f1.yaxis(d.value) + ')'
    );
    focus.select('.x-hover-line').attr('y2', f1.height - f1.yaxis(d.value));
    focus.select('.y-hover-line').attr('x2', f1.width + f1.width);

    // print info
    $(rootdiv)
      .find('.ginfo #year')
      .html(d.date.getFullYear());
    $(rootdiv)
      .find('.ginfo #accDeposits')
	  .html(formatNumberKM(Math.round(d.value)));
    //duplicata but doesn't work otherwise
    $(rootdiv)
      .find('.ginfo .g_posteriors')
      .css('display', 'table-row');

    //ginfo2
    $(rootdiv)
      .find('.ginfo2 .g_posteriors')
      .css('display', 'table-row');
    $(rootdiv)
      .find('.ginfo2 #year')
      .html(d.date.getFullYear());

    if (!thisParent.firstCall) {
      //ginfo
      $(rootdiv)
        .find('.ginfo #l34')
        .html('80-95');
      $(rootdiv)
        .find('.ginfo #l23')
        .html('20-80');
      $(rootdiv)
        .find('.ginfo #l12')
        .html('5-20');
      $(rootdiv)
        .find('.ginfo .l1')
        .html(
          formatNumberKM(
            Math.round(thisParent.current.post[i]['l1'] / 100) * 100
          )
        );
      $(rootdiv)
        .find('.ginfo .l2')
        .html(
          formatNumberKM(
            Math.round(thisParent.current.post[i]['l2'] / 100) * 100
          )
        );
      $(rootdiv)
        .find('.ginfo .l3')
        .html(
          formatNumberKM(
            Math.round(thisParent.current.post[i]['l3'] / 100) * 100
          )
        );
      $(rootdiv)
        .find('.ginfo .l4')
        .html(
          formatNumberKM(
            Math.round(thisParent.current.post[i]['l4'] / 100) * 100
          )
        );
      $(rootdiv)
        .find('.ginfo #Q50')
        .html(
          formatNumberKM(
            Math.round(thisParent.current.post[i]['l0.5'] / 100) * 100
          )
        );

      //	    $(rootdiv).find(".ginfo #Expected").html(parseMoneyInput(Math.round(thisParent.current_mean[i].value / 100) * 100))

      //ginfo2

      for (let ite of Object.entries(thisParent.current.projets[i])) {
        if (ite[0] == 'contr1' || ite[0] == 'contr2' || ite[0] == 'contr3')
          continue;
        if (ite[0] != 'date') {
          let tmp =
            Math.round(thisParent.current.projets[i][ite[0]]['l0.5'] / 100) *
            100;
          let tmpPercent = Math.round(
            (thisParent.current.pcrealise[i][ite[0]]['l0.5'] + Number.EPSILON) *
              100
          );

        let noSpace = ite[0].replace(/\s+/g, '')

        if (tmp > 0) {
            $(rootdiv)
              .find('.ginfo2 #Q50_' + noSpace)
              .html(formatNumberKM(tmp));
            $(rootdiv)
              .find('.ginfo2 #pcrealise_' + noSpace)
              .html(tmpPercent);
          } else {
            $(rootdiv)
              .find('#tr_' + noSpace)
              .css('display', 'none');
          }
        }
      }
      //$(rootdiv).find(".ginfo #Q50").html(parseMoneyInput(Math.round(thisParent.current.post[i]["l0.5"] / 100) * 100))
    }
  }
};

ExpectationsGraph.prototype.ComputeData = function(data, callback) {
  // create the call to cunit

  let thisParent = this;
  $.ajax({
    type: 'POST',
    async: true,
    contentType: 'application/json; charset=utf-8',
    url: this.urlquery + 'cunit/multiProjects',
    heaPrecers: {
      authorization: localStorage.getItem('priradToken'),
    },
    data: JSON.stringify(data),
    success: function(response) {
      thisParent.response = response;
      if ('error' in response) {
        if (typeof callback !== 'undefined') callback(response);
        return;
      }
      thisParent.ProcessServerResponse();

      // callback with response
      if (typeof callback !== 'undefined') callback(response);
    },
  });
};

ExpectationsGraph.prototype.ProcessServerResponse = function() {
  let response = this.response;

  // use callback in case of error

  /* Process data from new cunit call */


  let da = new Date();
  let year = da.getFullYear();
  let dates = [year];

  for (let i = 0; i < response.CumVersemts.length -1; i++) {
    year += 1;
    dates.push(year);
  }

  //change cmoney with cumversemts
  let cumvers = [];
  for (let i = 0; i < response.CumVersemts.length; i++) {
      cumvers.push(response.CumVersemts[i]);
  }
  // add quantiles
  let quantiles = [];
  for (let i = 0; i < response.tousQuantiles.PortefTotal.length; i++) {
      quantiles.push(response.tousQuantiles.PortefTotal[i]);
  }


  let proj_quantiles = {};
  let proj_pcrealise = {};

  for (let ite of Object.entries(response.tousQuantiles)) {
    if (ite[0] != 'PortefTotal' && ite[0] != 'Recyclage') {
      proj_quantiles[ite[0]] = [];
      proj_pcrealise[ite[0]] = [];
      for (let i = 0; i < ite[1].length; i++) {
          proj_quantiles[ite[0]].push(ite[1][i]);
          proj_pcrealise[ite[0]].push(response.pcRealise[ite[0]][i]);
      }

      if (proj_quantiles[ite[0]].length == 0) {
        delete proj_quantiles[ite[0]];
      }

      if (proj_pcrealise[ite[0]].length == 0) {
        delete proj_pcrealise[ite[0]];
      }
    }
  }


  /* REMOVE X ANS RETRAITES */
  let resparse = response.Projets
    let nbtorm = 0;
  if ('Retraite' in resparse) {
    nbtorm = Math.round(resparse['Retraite']['MoisCible']);

    for (let iterator of Object.entries(resparse)) {
      let nbtmp = Math.round(iterator[1]['MoisCible']);
      nbtorm = nbtmp > nbtorm ? nbtmp : nbtorm;
    }
  }


  if (nbtorm != 0) {
    let delta = Math.abs(dates.length - nbtorm);
    delta = delta - 1;
    dates.splice(dates.length - delta);
    cumvers.splice(cumvers.length - delta);
    quantiles.splice(quantiles.length - delta);

    for (let ite of Object.entries(proj_quantiles)) {
      ite[1].splice(ite[1].length - delta, delta);
    }
  }

  this.target = cumvers[cumvers.length - 1];

  this.projs_quantiles = proj_quantiles;
  this.projs_pcrealise = proj_pcrealise;
  this.quantiles = quantiles;

  // create fdata
  this.fdata.dates = dates.map(function(d) {
    return new Date(d.toString());
  });
  this.fdata.cashin = this.fdata.dates.map(function(d, id) {
    return {
      date: d,
      value: Math.round(cumvers[id]),
    };
  });

  this.CurrentData();
  this.ComputeProjects();
  // add projects in box info

  let udata = this.current.post;
  let f1 = this.f1;

  if (this.firstCall) {
    f1.g
      .append('path')
      .datum(udata)
      .attr('class', 'areasMoney')
      .attr('id', 'AMUp')
      .attr('d', f1.areas.up);
    f1.g
      .append('path')
      .datum(udata)
      .attr('class', 'areasMoney')
      .attr('id', 'AMMid')
      .attr('d', f1.areas.mid);
    f1.g
      .append('path')
      .datum(udata)
      .attr('class', 'areasMoney')
      .attr('id', 'AMLow')
      .attr('d', f1.areas.low);
    f1.g.select('path.cashin-line').moveToFront();
    f1.g.select('.ending').moveToFront();
    f1.g.select('.focus').moveToFront();
    // fade in
    // f1.g.selectAll(".areasMoney").transition().duration(500).style("opacity", 1);
    f1.g.selectAll('.areasMoney').style('opacity', 1);
    $(this.rootdiv)
      .find('.ginfo .g_posteriors')
      .css('display', 'table-row');
    $(this.rootdiv)
      .find('.target-fade')
      .show();

    this.firstCall = false;
  }

  this.updateFigBase();
  this.updateFig();
  this.updateTargetLine(this.target, 750);
};

ExpectationsGraph.prototype.ComputeProjects = function() {
  this.RenderTable2();

  let keys = Object.keys(this.projs_quantiles);
  //Allocation et réalisation par projet
  let newHtml = ` <tr style="border-bottom:1pt solid #CCC;">
    <th colspan="2" id="year" class="text-center" style="font-size:1.1em;"></th>
    </tr>
    <tr class="g_posteriors" style="border-bottom:1pt solid #CCC;">
    <th colspan="2" class="text-center" style="font-weight:bold;font-size30:1em;">${this.locale.realise}</th>
    </tr>
`;
  for (let ite of keys) {
    if (ite == 'contr1' || ite == 'contr2' || ite == 'Recyclage') continue;
    let name = ite in this.locale ? this.locale[ite] : ite;
    let noSpace = ite.replace(/\s+/g, '')
    newHtml += ` <tr id="tr_${noSpace}" class="g_posteriors">
<td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${name}</td>
        <td class="text-right" style="margin-right:20px;">
           <span id="Q50_${noSpace}"></span>€
              |
            <span id="pcrealise_${noSpace}"></span> %</td>
    </tr>`;
  }

  document.getElementById('table_projets').innerHTML = newHtml;
};

ExpectationsGraph.prototype.CurrentData = function() {
  this.current = { post: [], pdist: [] };

  //post = [{date:2000,l1:500,l2:500,l3:500,l4:500,l0.5:500}]
  //[.05, .20, .5, .8, .95]
  let lvls = ['l1', 'l2', 'l0.5', 'l3', 'l4'];
  let udata = [];
  let mdata = [];
  let meandata = [];
  let projsrealise = [];
  let risk_params = [
    0.012354792153809612,
    0.05926609295208244,
    0.10617739375035526,
    0.15308869454862808,
    0.1999999953469009,
  ];

  let projsdata = [];
  let projs_key = Object.keys(this.projs_quantiles);

  for (let i = 0; i < this.fdata.dates.length; i++) {
    let tmp = {
      date: this.fdata.dates[i],
    };
    let tmpProjs = {
      date: this.fdata.dates[i],
    };

    let tmp_mean = {
      date: this.fdata.dates[i],
    };

    let tmpProjsrealise = {
      date: this.fdata.dates[i],
    };
    tmp_mean['value'] = everpolate.linear(
      this.response.NoteTotale,
      risk_params,
      this.quantiles[i]
    )[0];

    for (let j = 0; j < lvls.length; j++) {
      tmp[lvls[j]] = this.quantiles[i][j];

      for (let k = 0; k < projs_key.length; k++) {
        if (!(projs_key[k] in tmpProjs)) {
          tmpProjs[projs_key[k]] = {};
          tmpProjsrealise[projs_key[k]] = {};
        }

        tmpProjs[projs_key[k]][lvls[j]] = this.projs_quantiles[projs_key[k]][i][
          j
        ];
        tmpProjsrealise[projs_key[k]][lvls[j]] = this.projs_pcrealise[
          projs_key[k]
        ][i][j];
      }
    }
    mdata.push(this.quantiles[i]);
    udata.push(tmp);
    projsdata.push(tmpProjs);
    projsrealise.push(tmpProjsrealise);
    meandata.push(tmp_mean);
  }

  this.current.post = udata;
  this.current.pdist = mdata;
  this.current_year = this.fdata.dates.length - 1;
  this.current.projets = projsdata;
  this.current.pcrealise = projsrealise;
  this.current_mean = meandata;
};

ExpectationsGraph.prototype.updateFigBase = function(time) {
  time = typeof time !== 'undefined' ? time : 750;
  let data = this.fdata.cashin;
  let f1 = this.f1;
  f1.xaxis.domain([
    data[0].date,
    new Date(data[data.length - 1].date.getFullYear() + 1, 1, 1),
  ]);

  f1.svg
    .select('.axis--x')
    .transition()
    .duration(time)
    .call(f1.Dxaxis);

  let id_tmp = data.length - 1;
  let xval = f1.xaxis(data[id_tmp].date);

  f1.ending
    .select('rect.end-area')
    .transition()
    .duration(time)
    .attr('x', xval)
    .attr('width', f1.width - xval);
};

ExpectationsGraph.prototype.updateFig = function(time) {
  time = typeof time !== 'undefined' ? time : 750;
  let udata = this.current.post;
  let f1 = this.f1;
  let data = this.fdata.cashin;

  let yaxis_values = [];

  this.fdata.cashin.forEach(function(d) {
    yaxis_values.push(d.value);
  });

  udata.forEach(function(d) {
    yaxis_values.push(d.l1);
    yaxis_values.push(d.l4);
  });

  f1.yaxis.domain([
    Math.min.apply(null, yaxis_values) / 1.005,
    Math.max.apply(null, yaxis_values),
  ]);

  f1.svg
    .select('.axis--y')
    .transition()
    .duration(time)
    .call(f1.Dyaxis);

  // resize lines and areas
  f1.svg
    .select('path.cashin-line') // change the line
    .transition()
    .duration(time)
    .attr('d', f1.lines.cashin(this.fdata.cashin));
  f1.svg
    .select('.areasMoney#AMUp')
    .transition()
    .duration(time)
    .attr('d', f1.areas.up(udata));
  f1.svg
    .select('.areasMoney#AMMid')
    .transition()
    .duration(time)
    .attr('d', f1.areas.mid(udata));
  f1.svg
    .select('.areasMoney#AMLow')
    .transition()
    .duration(time)
    .attr('d', f1.areas.low(udata));
};

ExpectationsGraph.prototype.updateTargetLine = function(target, time) {
  time = typeof time !== 'undefined' ? time : 0;
  target = typeof target !== 'undefined' ? target : this.target;
  this.target = target;

  let f1 = this.f1;
  let yval = f1.yaxis(target);

  let xval = f1.xaxis(this.fdata.dates[this.current_year]);

  /* let prob = everpolate.linear(target, this.current.pdist[this.current_year], this.response.finald.fqtiles)[0]; // or $('#ConfidenceSlider').slider("getValue")
    prob = prob > 1 ? 1 : (prob < 0 ? 0 : prob);
    prob = Math.round((1 - prob) * 1000) / 10; */

  $(this.rootdiv)
    .find('.target_amount')
    .html(parseMoneyInput(target));
  //$(this.rootdiv).find(".target_prob").html(prob)
  f1.ending.select('text.target-title').text(
    $(this.rootdiv)
      .find('#labelObjective')
      .html()
      .replace(/<[^>]*>/g, '')
  );
  if (time == 0) {
    f1.ending.select('circle.target-circle').attr('cy', yval);
    f1.ending
      .select('line.target-line')
      .attr('y1', yval)
      .attr('y2', yval);
    f1.ending.select('text.target-title').attr('y', yval - 15);
  } else {
    f1.ending
      .select('circle.target-circle')
      .transition()
      .duration(time)
      .attr('cy', yval)
      .attr('cx', xval);
    f1.ending
      .select('line.target-line')
      .transition()
      .duration(time)
      .attr('y1', yval)
      .attr('y2', yval);
    f1.ending
      .select('text.target-title')
      .transition()
      .duration(time)
      .attr('y', yval - 15);
  }

  //return prob;
};
