import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import lodash from 'lodash';
import './assets/css/bootstrap-slider.min.css';
import './assets/css/custom.css';
import './assets/css/graph_builder.css';
import './assets/css/nestable.css';
import './assets/css/rangeselector.css';
import './assets/css/style.css';
import './assets/css/range_button_manager.css';
import './assets/css/roundbuttons.css';
import './assets/css/font-awesome.min.css';
import './assets/css/simple-line-icons.css';
import './assets/js/graph/BaseChart.js';
import './assets/css/checkbox.css';
import './assets/css/checkbox-full-btn.css';

/* simulation */
import Slider from './assets/js/graph/bootstrap-slider.min.js';
import './assets/js/graph/everpolate.min.js';
import './assets/js/graph/graph_helper.js';
import './assets/js/graph/utils.js';
import i18n from './i18n';

Vue.prototype.$slider = Slider;
Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.headers.common[
  'Authorization'
] = localStorage.getItem('priradToken');
Vue.prototype.$_ = lodash;
Vue.config.productionTip = false;

function formatInt(x) {
  let nb = Math.ceil(x / 100) * 100;
  return nb
    .toString()
    .replace(' ', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

Vue.prototype.$formatNumberKM = function(number) {
    let deli = i18n.locale == "en" ? "," : ".";
  let SI_SYMBOL = ['', ' K', ' M', ' G', ' T', ' P', ' E'];

  // what tier? (determines SI symbol)
  let tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return Math.round(number) + ' ';

  // get suffix and determine scale
  let suffix = SI_SYMBOL[tier];
  let scale = Math.pow(10, tier * 3);

  // scale the number
  let scaled = number / scale;

  // format number and add suffix
  return number < 10000
    ? formatInt(number) + ' '
    : suffix === ' K'
    ? scaled.toFixed(0).replace(/\./g, deli) + suffix
    : scaled.toFixed(1).replace(/\./g, deli) + suffix;
};

Vue.prototype.$getCookies = function(name) {
  let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
  if (match) return match[1];
  else return false;
};

Vue.prototype.$clearCookie = function(name) {
  //=;expires=Thu, 01 Jan 1970 00:00:00 GMT
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
};

Vue.prototype.$getIsLogged = function() {
  let match = document.cookie.match(
    new RegExp('priradUserIsLogged' + '=([^;]+)')
  );
  if (match) return match[1];
  else return false;
};

Vue.prototype.$getRole = function() {
  let match = document.cookie.match(new RegExp('priradUserRole' + '=([^;]+)'));
  if (match) return match[1];
  else return false;
};

router.beforeEach((to, from, next) => {
  document.title = 'PrimeRadiant- ' + to.name;
  next();
});

let vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

window.app = vm;
