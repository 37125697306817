<template>
  <div>
    <h4 style="margin-bottom: 15px">{{ $t('NEW_ACCOUNT') }}</h4>
    <div class="col-12">
      <div class="modal-body">
        <div class="navbar">
          <div class="navbar-inner">
            <ul
              class="nav nav-pills"
              style="margin-left: 0px; margin-bottom: 15px"
            >
              <li>
                <span @click="clickLink('Client')" :class="Client">
                  <i class="fa fa-user"></i>
                  {{ $t('CLIENT') }}
                </span>
              </li>
              <!-- <li>
                <span @click="clickLink('Apports')" :class="Apports">
                  <i class="fa fa-eur"></i>
                  {{ $t('YOUR_SAVINGS') }}
                </span>
              </li> -->
              <li>
                <span @click="clickLink('ESG')" :class="ESG">
                  <i class="fa fa-leaf" />
                  {{ $t('ESG') }}
                </span>
              </li>
              <li>
                <span @click="clickLink('KYC')" :class="KYC">
                  <i class="fa fa-file" />
                  {{ $t('KYC') }}
                </span>
              </li>
              <li>
                <span @click="clickLink('Contr')" :class="Contr">
                  <i class="fa fa-lock"></i>
                  {{ $t('CONSTRAINTS') }} & {{ $t('PREFERENCES') }}
                </span>
              </li>
              <li>
                <span @click="clickLink('Obj')" :class="Obj">
                  <i class="icon-target"></i>
                  {{ $t('GOALS') }}
                </span>
              </li>
              <li>
                <span @click="clickLink('Simu')" :class="Simu">
                  <i class="fa fa-calculator"></i>
                  {{ $t('SIMULATION') }}
                </span>
              </li>
              <li>
                <span @click="clickLink('Res')" :class="Res">
                  <i class="fa fa-list-ol"></i>
                  {{ $t('SUMMARY') }}
                </span>
              </li>
              <!-- <li>
                <span @click="clickLink('KYC')" :class="KYC">
                  <i class="fa fa-question"></i>
                  {{ $t('SUITABILITY_TEST') }}
                </span>
              </li> -->
              <!-- <li>
                <span @click="clickLink('Val')" :class="Val">
                  <i class="fa fa-check"></i>
                  {{ $t('VALIDATION') }}
                </span>
              </li> -->
              <li>
                <span @click="clickLink('Visu')" :class="Visu">
                  <i class="fa fa-check"></i>
                  {{ $t('VISUALIZATION') }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="progress">
          <div
            class="progress-bar progress-bar-success"
            role="progressbar"
            :style="styleEtape"
          >
            {{ $t('STEP_OUT_OF', { etape, etape_total: 9 }) }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-2">
            <span
              @click="preRemplir()"
              class="btn btn-primary btn-sm myNavInfo"
            >
              <i class="fa fa-list-ol"></i>
              <!---  {{ $t('PRE_FILL') }} ---->
              data
            </span>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <client_New_Compte v-if="etape == 1" @clicked="continuerEtape" />
        <apports v-if="etape == 2" @clicked="continuerEtape" />
        <ESG v-if="etape == 3" @clicked="continuerEtape" />
        <newKYC v-if="etape == 4" @clicked="continuerEtape"/>
        <contraintes
          v-if="etape == 5"
          @clicked="continuerEtape"
          :guidProps="idChangeNav"
        />
        <objectifs_New_Compte v-if="etape == 6" @clicked="continuerEtape" />
        <simulation ref="simu" v-if="etape == 7" @clicked="continuerEtape" />
        <resume v-if="etape == 8" @clicked="continuerEtape" />
        <!-- <kyc_New_Compte v-if="etape == 8" @clicked="continuerEtape" /> -->
        <!-- <validation v-if="etape == 8" @clicked="continuerEtape" /> -->
        <visualisation_new_compte v-if="etape == 9" @clicked="continuerEtape" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import Client_New_Compte from '@/components/agent/nouveau_compte/client.vue';
import Apports from '@/components/agent/nouveau_compte/apports.vue';
import Contraintes from '@/components/agent/nouveau_compte/contraintes.vue';
import Objectifs_New_Compte from '@/components/agent/nouveau_compte/objectifs.vue';
import Resume from '@/components/agent/nouveau_compte/resume.vue';
import KYC_New_Compte from '@/components/agent/nouveau_compte/kyc.vue';
import Simulation from '@/components/agent/nouveau_compte/simulation.vue';
import Validation from '@/components/agent/nouveau_compte/validation.vue';
import Visualisation_new_compte from '@/components/agent/nouveau_compte/visualisation.vue';
import ESG from '@/components/agent/nouveau_compte/esg.vue'
import newKYC from '@/components/agent/nouveau_compte/newKYC.vue'

Vue.component('visualisation_new_compte', Visualisation_new_compte);
Vue.component('client_New_Compte', Client_New_Compte);
Vue.component('apports', Apports);
Vue.component('contraintes', Contraintes);
Vue.component('objectifs_New_Compte', Objectifs_New_Compte);
Vue.component('resume', Resume);
Vue.component('kyc_New_Compte', KYC_New_Compte);
Vue.component('simulation', Simulation);
Vue.component('validation', Validation);

export default {
  components: {
    ESG,
    newKYC
  },
  data() {
    return {
      Client: 'btn btn-primary btn-sm myNavInfo active',
      Apports: 'btn btn-primary btn-sm myNavInfo',
      ESG: 'btn btn-primary btn-sm myNavInfo',
      Contr: 'btn btn-primary btn-sm myNavInfo',
      Obj: 'btn btn-primary btn-sm myNavInfo',
      Simu: 'btn btn-primary btn-sm myNavInfo',
      Res: 'btn btn-primary btn-sm myNavInfo',
      KYC: 'btn btn-primary btn-sm myNavInfo',
      Val: 'btn btn-primary btn-sm myNavInfo',
      Visu: 'btn btn-primary btn-sm myNavInfo',
      etape: 1,
      styleEtape: 'width: 12.5%;',
      allClient: {},
      idChangeNav: '',
    };
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,
      token: (state) => state.token,
    }),
  },
  methods: {
    ...mapActions([
      'setBreadLink',
      'setToken',
      'setNewCompte',
      'setRiskNote',
      'setLogged',
      'setRisk',
      'setNewNoteVisu',
      'setStoreInfoToOrigin',
    ]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });

        await this.$http.get(this.baseUrl + 'auth/logout');

        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role != 10) {
          this.setLogged({ isLog: false, role: 0 });

          await this.$http.get(this.baseUrl + 'auth/logout');

          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    clickLink(val) {
      this.changeEtape(val);
    },
    continuerEtape(val) {
      if (val.next != '') this.changeEtape(val.next);
    },
    changeEtape(val) {
      let styleNo = 'btn btn-primary btn-sm myNavInfo';
      let style = 'btn btn-primary btn-sm myNavInfo active';
      let arr = [
        'Client',
        'Apports',
        'ESG',
        'KYC',
        'Contr',
        'Obj',
        'Simu',
        'Res',
        // 'KYC',
        // 'Val',
        'Visu',
      ];
      let arrWidth = [
        'width: 11.1%;',
        'width: 22.2%;',
        'width: 33.3%;',
        'width: 44.4%;',
        'width: 55.5%;',
        'width: 66.6%;',
        'width: 77.7%;',
        'width: 88.8%;',
        'width: 99.9%;',
      ];
      let ctx = this;
      let i = 0;
      for (const item of arr) {
        if (item != val) this[item] = styleNo;
        else if (item == val) {
          this[item] = style;
          this.etape = i + 1;
          this.styleEtape = arrWidth[i];
        }
        i++;
      }
      this.idChangeNav = this.guidGenerator();
    },
    preRemplir() {
      let obj = {
        note: 4.6,
        kyc_vol: 0.167,
        client: {
          name: 'Homer',
          prenom: 'Amélie',
          phone: '0606060606',
          mail: 'homeramelie@gmail.com',
        },
        apports: {
          initial: '20000',
        },
        infos: {
          personnalRiskProfile: {
                timeConsistency: 0,
                ageConsistency: 0,
                investmentObjectiveConsistency: 0,
                capacityConsistency: 0,
            },
            financialProfile: {
                realEstate: "1000000"
            },
            account: {
                estimatedAnnualContribution: "1000",
                duration: 5
            },
            esg: {
                introductionQuestion: {
                  itemsKeys: [
                    "environment_objective",
                    "social_objective",
                    "other_objective"
                  ],
                  itemsScores: [2, 3, 0]
                },
                esgGame: {
                  itemsWeights: [70,0,0,30,0],
                  itemsKeys: [
                    "esg.climate_change_adaptation_and_mitigation",
                    "esg.biodiversity",
                    "esg.pollution_and_water_resources_preservation",
                    "esg.circular_economy",
                    "esg.other"
                  ]
                },
                pai: []
            },
        },
        newKYC: {
          annualVolatility: 15,
          liquidAssets: "10000",
          financialInvestments: "100000",
          other: "10000",
          debt: "3000", //Total Current and Future Financial Commitments
          annualIncome: "30000",
          incompressibleSpending: "10000",
        },
        contr: {
          contr1: '0',
          createdAt: new Date().toISOString(),
          contr2: '0',
          contr3: '0',
        },
        graph: {
          response: {
            NoteTotale: 2.8,
          },
        },
        contraintes: {},
        objectifs: {
          transmi: {
            isCheck: true,
            prio: false,
            amount: "20000",
            duree: "10",
            name:"Pass on",
            createdeatedAt: new Date().toISOString(),
          },
          immo: {
            data: [
              {
                date: '5',
                duree: '15',
                percent: '0',
                value: '1500000',
                name: 'Appartement',
                proba: null,
                esperance: null,
                note: null,
                createdAt: new Date().toISOString(),
              },
            ],
            isCheck: false,
            prio: false,
          },
          retraite: {
            duree: '15',
            rent: '2000',
            isCheck: false,
            prio: false,
            proba: null,
            esperance: null,
            note: null,
            createdAt: new Date().toISOString(),
          },
          school: {
            isCheck: false,
            data: [
              {
                name: 'Sophie',
                year: 12,
                duration: 5,
                expenses: '50000',
                proba: null,
                esperance: null,
                note: null,
                createdAt: new Date().toISOString(),
              },
            ],
            prio: false,
          },
          autre: {
            amount: '20000',
            duree: '10',
            isCheck: false,
            prio: false,
            createdAt: new Date().toISOString(),
          },
          precaution: {
            isCheck: true,
            prio: false,
            amount: 20000,
            date: "10",
           createdAt: new Date().toISOString()
          },
        },
        old_objectifs: {
       transmi: {
            isCheck: true,
            prio: false,
            amount: "20000",
            duree: "10",
            name:"Pass on",
            createdeatedAt: new Date().toISOString(),
          },
          immo: {
            data: [
              {
                date: '5',
                duree: '15',
                percent: '0',
                value: '1500000',
                name: 'Appartement',
                proba: null,
                esperance: null,
                note: null,
                createdAt: new Date().toISOString(),
              },
            ],
            isCheck: false,
            prio: false,
          },
          retraite: {
            duree: '15',
            rent: '2000',
            isCheck: false,
            prio: false,
            proba: null,
            esperance: null,
            note: null,
            createdAt: new Date().toISOString(),
          },
          school: {
            isCheck: false,
            data: [
              {
                name: 'Sophie',
                year: 12,
                duration: 5,
                expenses: '50000',
                proba: null,
                esperance: null,
                note: null,
                createdAt: new Date().toISOString(),
              },
            ],
            prio: false,
          },
          autre: {
            amount: '20000',
            duree: '10',
            isCheck: false,
            prio: false,
            createdAt: new Date().toISOString(),
          },
          precaution: {
            isCheck: true,
            prio: false,
            amount: 20000,
            date: "10",
            createdAt: new Date().toISOString()
          },
        },
        kyc: {
          experience: {
            monetary: '-1',
            q1: '-1',
            q2: '1',
          },
          objectifs: {
            preo: '-1',
            choicePref: '2',
          },
          risk: {
            fluctuation: '0.5',
          },
          suit: {
            situation: '2',
            depart: '2040',
            revenuTot: '90000',
            revenue_type: '0',
            evolution_5_years: '1',
            patrimoine: '800000',
            patMonthLoan: '1500',
            patTermLoan: '5',
            monthlyExpenses: '350',
            amountCheck: '2',
            saveCheck: '2',
          },
        },
        others: [],
        isNew: true,
        extra_info: { oddo_constraint: true },
      };

      this.setNewCompte(obj);
      this.setStoreInfoToOrigin();
      this.setRisk({
        bar: { max: '3.64', min: '2.39' },
        proposed_risk: '3.14',
        riskframe: 3,
        volatility: '8.8',
      });
      this.setRiskNote(3);
      this.setNewNoteVisu(2.5);
    },
    guidGenerator() {
      let S4 = function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      );
    },
  },
  mounted() {
    this.setBreadLink(['Prime Radiant', 'COUSELORS', 'NEW_ACCOUNT']);
  },
  created() {
    this.checkLog();
  },
};
</script>

<style>
.myNavInfo {
  cursor: pointer;
  margin-right: 5px;
}
</style>
