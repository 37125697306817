<template>
  <div>
    <table
      class="
        table table-striped table-condensed table-bordered
        datatable
        tableStyle
      "
    >
      <thead>
        <tr>
          <th @click="setSort('flags.no_kyc')">
            <i
              v-if="!(isSortedBy('flags.no_kyc'))"
              data-toggle="tooltip"
              data-placement="top"
              title="Respect de la compliance"
              class="fa fa-check-circle"
            />
            <i v-else
              :class="[ sorts['flags.no_kyc'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
             />
          </th>
          <th @click="setSort('lastname')">
            {{ $t('CLIENT') }}
            <i v-if="isSortedBy('lastname')"
              style="float: right"
              :class="[ sorts['lastname'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
            />
          </th>
          <th @click="setSort('created')">
            {{ $t('SINCE') }}
            <i v-if="isSortedBy('created')"
              style="float: right"
              :class="[ sorts['created'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
            />
          </th>
          <th @click="setSort('n_contracts')">
            {{ $t('WALLETS') }}
            <i v-if="isSortedBy('n_contracts')"
              style="float: right"
              :class="[ sorts['n_contracts'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
            />
          </th>
          <th @click="setSort('current')">
            {{ $t('VALUE') }}
            <i v-if="isSortedBy('current')"
              style="float: right"
              :class="[ sorts['current'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
            />
          </th>
          <th @click="setSort('gains.percent')">
            {{ $t('%_EARNINGS') }}
            <i v-if="isSortedBy('gains.percent')"
              style="float: right"
              :class="[ sorts['gains.percent'] == '1' ?
                'icon-arrow-up-circle' : 
                'icon-arrow-down-circle'
              ]"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in clientProps" :key="index">
          <td>
            <button
              type="button"
              :class="[ !item.flags.no_kyc ?
                'btn btn-link text-success': 
                'btn btn-link text-danger'
              ]"
            >
              <i class="fa fa-check-circle"></i>
            </button>
          </td>
          <td>
            <router-link v-if="item.id" :to="'/dashboard/' + item.id"
              >{{ item.lastname }} {{ item.name }}</router-link
            >
            <router-link v-else :to="'/dashboard/' + item._id"
              >{{ item.lastname }} {{ item.name }}</router-link
            >
          </td>
          <td>{{ dateFormat(item.created) }}</td>
          <td>{{ item.n_contracts }}</td>
          <td>{{ encoursFormat(Math.round(item.current)) }} €</td>

          <td>{{ Math.round(item.gains.percent * 100) }} %</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sorts: {
        lastname: '',
        created: '',
        n_contracts: '',
        encours: '',
        current: '',
        'gains.percent': '',
      },
    };
  },
  props: {
    clientProps: {
      required: true,
      type: Array,
      default: [],
    },
    sortList : {
      required: true,
      type: Array
    }
  },
  methods: {
    isSortedBy(value){
      let findIdx = this.sortList.findIndex(el => el.sort === value)
      return findIdx !== -1 ? true : false
    },
    setSort(val) {
      if (this.sorts[val] == '-1' || this.sorts[val] == '')
        this.sorts[val] = '1';
      else this.sorts[val] = '-1';

      let asc = this.sorts[val];
      let sort = {
        sort: val,
        asc,
      };
      this.$emit('clicked', sort);
    },
    dateFormat: function (x) {
      let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      let current = new Date(x);

      return current.toLocaleDateString('fr-FR', options);
    },
    encoursFormat(x) {
      var parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    },
  },
};
</script>

<style>
.iMarginR {
  margin-right: 0.5rem;
}

.iMarginRPixel {
  margin-right: 5px;
}

.iMarginL {
  margin-left: 10px;
}

.tableStyle {
  font-size: 18px;
  padding: 0px;
  text-align: center;
}
</style>
