import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookie from 'js-cookie';

Vue.use(Vuex);
let getCookies = function (name) {
  let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
  if (match) return match[1];
  else return false;
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['token'],
      getState: (key) => Cookie.get(key),
      setState: (key, state) =>
        Cookie.set(key, state, {
          expires: 7,
          secure: true,
        }),
    }),
  ],
  state: {
    breadLink: ['Prime Radiant'],
    baseUrl: '',
    token: '',
    isLogged: {
      isLog: JSON.parse(getCookies('priradUserIsLogged')),
      role: JSON.parse(getCookies('priradUserRole')),
    },
    pfreference: {},
    new_Compte: {
      note: null,
        kyc_vol: null,
      contraintes: {},
      client: {},
      apports: {},
      infos: {
        personnalRiskProfile: {
        },
        financialProfile: {},
        account: {},
        esg: {
          introductionQuestion: {
            itemsKeys: [
              "environment_objective",
              "social_objective",
              "other_objective"
            ],
            itemsScores: [0,0,0]
          },
          esgGame: {
            itemsWeights: [0,0,0,0,0],
            itemsKeys: [
              "esg.climate_change_adaptation_and_mitigation",
              "esg.biodiversity",
              "esg.pollution_and_water_resources_preservation",
              "esg.circular_economy",
              "esg.other"
            ]
          },
          pai: []
        },
      },
      newKYC:{},
      contr: {},
      old_objectifs: {
        immo: { data: [], prio: false },
        retraite: {
          prio: false,
        },
        school: {
          data: [],
          prio: false,
        },
        autre: {
          prio: false,
        },
        transmi: {
          prio: false,
        },
        precaution: {
          prio: false,
        },
      },
      objectifs: {
        immo: { data: [], prio: false },
        retraite: {
          prio: false,
        },
        school: {
          data: [],
          prio: false,
        },
        autre: {
          prio: false,
        },
        transmi: {
          prio: false,
        },
        precaution: {
          prio: false,
        },
      },
      kyc: {
        experience: {},
        objectifs: {},
        risk: {},
        suit: {},
      },
      others: [],
      isNew: true,
      extra_info: {
        oddo_constraint: false,
      },
      graph: {},
    },
    risk: {},
    riskVisualisation: 0,
    client_Info: {},
    client_Portfolio: {},
    simulation: {},
  },
  mutations: {
    ['SET_Bread_Link'](state, payload) {
      state.breadLink = payload;
    },
    ['SET_Base_Url'](state, payload) {
      state.baseUrl = payload;
    },
    ['SET_Token'](state, payload) {
      state.token = payload;
      window.localStorage.setItem('priradToken', payload);
    },
    ['SET_New_Compte'](state, payload) {
      state.new_Compte = payload;
    },
    ['SET_Risk'](state, payload) {
      state.risk = payload;
    },
    ['SET_Client_Info'](state, payload) {
      state.client_Info = payload;
    },
    ['SET_Client_Portfolio'](state, payload) {
      state.client_Portfolio = payload;
    },
    ['SET_Simulation'](state, payload) {
      state.simulation = payload;
    },
    ['SET_Logged'](state, payload) {
      state.isLogged = payload;
    },
    ['SET_ReferencePf'](state, payload) {
      state.pfreference = payload;
    },
    ['SET_NEW_ACCOUT_CONTRAINTES'](state, payload) {
      state.new_Compte.contraintes = payload;
    },
    ['SET_NEW_NOTE_VISU'](state, payload) {
      state.riskVisualisation = payload;
    },
    ['SET_STORE_INFO_TO_ORIGIN'](state) {
      state.risk = {};
      state.riskVisualisation = 0;
      state.client_Info = {};
      state.client_Portfolio = {};
      state.simulation = {};
    },
  },
  actions: {
    setBreadLink({ commit }, payload) {
      commit('SET_Bread_Link', payload);
    },
    setBaseUrl({ commit }, payload) {
      commit('SET_Base_Url', payload);
    },
    setToken({ commit }, payload) {
      commit('SET_Token', payload);
    },
    setNewCompte({ commit }, payload) {
      commit('SET_New_Compte', payload);
    },
    setRisk({ commit }, payload) {
      commit('SET_Risk', payload);
    },
    setClientInfo({ commit }, payload) {
      commit('SET_Client_Info', payload);
    },
    setClientPortfolio({ commit }, payload) {
      commit('SET_Client_Portfolio', payload);
    },
    setSimulation({ commit }, payload) {
      commit('SET_Simulation', payload);
    },
    setRiskNote({ commit }, payload) {
      commit('SET_RiskNote', payload);
    },
    setLogged({ commit }, payload) {
      commit('SET_Logged', payload);
    },
    setReferencePf({ commit }, payload) {
      commit('SET_ReferencePf', payload);
    },
    setNewAccountContraintes({ commit }, payload) {
      commit('SET_NEW_ACCOUT_CONTRAINTES', payload);
    },
    setNewNoteVisu({ commit }, payload) {
      commit('SET_NEW_NOTE_VISU', payload);
    },
    setStoreInfoToOrigin({ commit }) {
      commit('SET_STORE_INFO_TO_ORIGIN');
    },
  },
});
