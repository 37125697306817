<template>
  <div>
    <notifications
      classe="n-light"
      group="save"
      position="top center"
      :max="3"
      :width="400"
    />
    <div class="d-flex justify-content-between">
      <h5 class="align-self-center m-0">
        <i class="fa fa-asterisk" style="color: #36a9e1"></i>
        {{ $t('PREFERENCES') }}
      </h5>
      <button
        v-if="showButtonSaveProps"
        type="button"
        class="btn btn-primary"
        @click="savePreferences()"
      >
        {{ $t('SAVE') }}
      </button>
    </div>
    <hr />
    <div>
      <ul class="d-flex p-0 mb-0">
        <li v-for="menu in cardMenu" :key="menu.card"
            class="nav-link"
            :class="menu.card === cardSelected ? 'nav-link-active' : ''"
            @click="toggleCard(menu.card)"
        >
          {{ $t(menu.title) }}
        </li>
      </ul>
      <div>
        <div
          class="d-flex justify-content-end"
          v-if="cardSelected !== 'stocks'"
        >
          <table class="pref-table table-striped table-condensed text-center">
            <thead>
              <th></th>
              <th>{{ $t('NONE_PREFERENCES') }}</th>
              <th>{{ $t('WITH_PREFERENCE') }}</th>

              <!-- <th>{{ $t('ABIT_PREFERENCES') }}</th> -->
              <!-- <th>{{ $t('MANY_PREFERENCES') }}</th> -->
            </thead>
            <tbody>
              <template>
                <tr v-for="pref in getSelectedContent" :key="pref+'_'+generateUuid">
                  <td>{{ $t(pref) }}</td>
                  <td>
                    <input
                      type="radio"
                      :name="`${cardSelected}_` + pref"
                      @click="uncheck(pref)"
                      :checked="!(pref in contraintes)"
                    />
                  </td>
                  <td>
                    <input
                      type="radio"
                      :name="`${cardSelected}_` + pref"
                      @click="checkContraintes(pref, '1')"
                      value="1"
                      :checked="pref in contraintes && contraintes[pref] == 1"
                    />
                  </td>
                  <!-- <td>
                    <input
                      type="radio"
                      :name="`${cardSelected}_` + pref"
                      @click="checkContraintes(pref, '2')"
                      value="2"
                      :checked="pref in contraintes && contraintes[pref] == 2"
                    />
                  </td> -->
                </tr>
              </template>
            </tbody>
          </table>
        </div>
	<span v-if="cardSelected == 'wishes'" style="font-size:x-small;" > {{$t("INCLUSION_INCOMPLETE")}}</span>
        <div class="text-center" v-if="cardSelected === 'stocks'" style="background-color: #F1F1F1; padding: 10px;">
          <div>
            <button class="stockBtn" @click="enableStock">
              {{getBtnStockText}} Stocks
            </button>
          </div>
          <div v-if="btnStockIsEnable">
            <div class="d-flex justify-content-between">
              <CustomSelect
                :list="stockCategories"
                :index="-1"
                optionPlaceholder="Categories (all)"
                @change="selectedStockCategory = $event"
                style="width: 300px;"
              />
              <div>
                <input
                  class="stockSearch"
                  type="text"
                  placeholder="Search.."
                />
              </div>
              <div class="d-flex checkbox-full-btn">
                <input
                  :id="'contraintPrefCheckAllStock'"
                  type="checkbox"
                  :checked="btnAllChecked"
                  @change="checkAllStocks()"
                />
                <label :for="'contraintPrefCheckAllStock'" style="margin-bottom: 0">
                  {{$t('ALL')}}
                </label>
              </div>
            </div>

            <div class="checkboxContainer">
              <div v-for="stock in filteredStockList" :key="stock.isin">
                <div class="stock">
                  {{ stock.name }}

                  <label class="check">
                    <input
                      type="checkbox"
                      class="checkbox"
                      :checked="contraintes.stocks[stock.isin].checked"
                      @change="checkStocks(stock)"
                    />
                    <div class="box"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Notifications from 'vue-notification';
import CustomSelect from '@/components/autre/customSelect.vue';
import uuidMixin from '@/mixins/uuid'

Vue.use(Notifications);

export default {
  mixins: [uuidMixin],
  components: {
    CustomSelect
  },
  data() {
    return {
      // cardSelected: 'wishes',
      cardSelected: 'regions',
      cardMenu: [
        // {card: 'wishes', title: "PREFERENCES_OPTIONS"},
        // {card: 'currencies', title: 'CURRENCIES_NO_DOT'},
        // {card: 'region', title: 'REGIONAL_PREFERENCES_NO_DOT'},
        // {card: 'sector', title: 'SECTORIAL_PREFERENCES_NO_DOT'},
        {card: 'regions', title: 'REGIONS'},
        {card: 'sectorThemes', title: 'SECTOR_AND_THEMES'},
        {card: 'stocks', title: 'STOCKS_PREFERENCES_NO_DOT'}
      ],
      // sector: [
      //   'TECHNOLOGY_CONTR',
      //   'REAL_ESTATE_CONTR',
      //   'ENERGY_AND_COMMODITIES',
      //   'OR_&_METAUX_PRECIEUX',
      // ],
      // sectorThemes: [
      //   "GOLD",
      //   "Tech",
      //   "PHARMA",
      //   "CONSUMER_GOODS",
      //   "LUXURY_GOODS",
      //   "BIOTECH",
      //   "USD_ASSETS",
      //   "INFLATION_PROTECTION"
      // ],
      sectorThemes: [
        "gold",
        "tech",
        "pharma",
        "consumer_goods",
        "luxury_goods",
        "biotech",
        "usd_assets",
        "inflation_protection"
      ],
      // region: ['USA_CONTR', 'EMERGING_CONTR'],
      // regions: ['USA_CONTR', 'EMERGING_CONTR', 'ITALY_CONTR'],
      regions: ['usa', 'china_emerging', 'italy'],
      currencies: ['USD_CONTR', 'GBP_CONTR', 'CHF_CONTR'],
	wishes: [
	    "FONDS_IMMOBILIERS_SCPI",
	    "PREFERENCES_SOCIAL_RESPONSIBLE_INVESTMENT",
	    "CRYPTO"
      ],
      contraintes: {},
      portfolio: {},
      // stockCategories: ['TECH_PREFERENCES', 'EUROPE_PREFERENCES'],
      stockCategories: ['ITALY_CONTR', 'GLOBAL'],
      selectedStockCategory: -1,
      btnAllChecked: false,
      btnStockIsEnable: false
    };
  },
  props: {
    showButtonSaveProps: {
      type: Boolean,
      required: false,
      default: false,
    },
      prefProps: {
	  type: Object,
	  required: false,
	  default: false,
      },
      isFromNewAcc: {
	  type: Boolean,
	  required: false,
	  default: false,
      }
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
	client: (state) => state.client_Info,
	new_acc: (state) => state.new_Compte.contraintes,
    }),
    getSelectedContent() {
      return this.$data[this.cardSelected]
    },
    getBtnStockText() {
      return this.btnStockIsEnable ? 'Disable' : 'Enable'
    },
    filteredStockList(){
      let list = {}
      if(this.selectedStockCategory === -1){
        list = this.contraintes.stocks
      } else {
        let type = this.mapShowStockValue(this.selectedStockCategory)
        for (const [key, value] of Object.entries(this.contraintes.stocks)) {
          if(value.type === type) {
            list[key] = value
          }
        }
      }
      return list
    }
  },
  methods: {
    savePreferences() {
      this.$emit('savePreferences', this.contraintes);
    },
    toggleCard(section) {
      this.cardSelected = section
    },
    uncheck(pref) {
	delete this.contraintes[pref];
	this.$emit("changePreferences", this.contraintes)
    },
    async getPortfolio() {
      let pfs = await this.$http.get(
        this.baseUrl +
          'users/dashboard/portfolios/portfolios/' +
          localStorage.getItem('priradCompany'),
        {
          headers: {
            Authorization: localStorage.getItem('priradToken'),
          },
        }
      );
      this.portfolio = pfs.data.resultat[0];
    },

    getTypeOfStock(assetName) {
      const ITALY = 'Italy-General';

      switch (assetName) {
        case ITALY:
          return 'italy';
        default:
          return 'global';
      }
    },
    checkIfAllStockAreChecked() {
      let isChecked = true;

      for (let item of Object.entries(this.contraintes.stocks)) {
        if (!item[1].checked) {
          isChecked = false;
          break
        }
      }
      return isChecked;
    },
    checkStocks(stock) {
      let stocks = this.contraintes.stocks
      stocks[stock.isin].checked = !stock.checked
      this.contraintes = Object.assign({}, this.contraintes, { stocks });
	this.btnAllChecked = this.checkIfAllStockAreChecked()
	this.$emit("changePreferences", this.contraintes)
    },
    checkAllStocks() {
      this.btnAllChecked = !this.btnAllChecked
      let stocks = this.contraintes.stocks
      for (let item of Object.entries(stocks)) {
        item[1].checked = this.btnAllChecked
      }

	this.contraintes = Object.assign({}, this.contraintes, { stocks });
	this.$emit("changePreferences", this.contraintes)
    },
    mapShowStockValue(index){
      let type = ''

      switch(this.stockCategories[index]){
        case 'TECH_PREFERENCES':
          type = 'techno'
          break
        case 'EUROPE_PREFERENCES':
          type = 'europe'
          break
        case 'ITALY_CONTR':
          type = 'italy'
          break
        case 'GLOBAL':
          type = 'global'
          break
      }
        return type
    },
    checkContraintes(pref, value) {
      let tmp = {};
      tmp[pref] = value;
	this.contraintes = Object.assign({}, this.contraintes, tmp);
	this.$emit("changePreferences", this.contraintes)
    },
    checkIfConstraintHasAllStock(){
      let stockList = this.getStockList()

      for (const stock of Object.entries(stockList)) {
        if(!(this.contraintes.stocks.hasOwnProperty(stock[0]))){
          this.contraintes.stocks[stock[0]] = stock[1]
        }
      }

	this.contraintes = Object.assign({}, this.contraintes, this.contraintes);
	this.$emit("changePreferences", this.contraintes)
    },
      getClientConstraintes() {
	  if(this.isFromNewAcc) {
	      this.contraintes = Object.assign({}, this.contraintes, this.new_acc);
	  } else {
	      this.contraintes = Object.assign({}, this.contraintes, this.client.preferences);
	  }
      if('stocks' in this.contraintes) {
        this.btnStockIsEnable = true
        this.checkIfConstraintHasAllStock()
      }
    },
    getStockList() {
      let stocks = {};
      for (let univers of Object.entries(this.portfolio.contents)) {
        for (let asset of Object.entries(univers[1].assets)) {
          for (let fund of asset[1].funds) {
            if (fund.stock) {
              stocks[fund.ISIN] = {
                name: fund.Name,
                checked: false,
                isin: fund.ISIN,
                type: this.getTypeOfStock(asset[0]),
              };
            }
          }
        }
      }
      return stocks
    },
    enableStock() {
      this.btnStockIsEnable = !this.btnStockIsEnable

      this.btnAllChecked = false

      if(this.btnStockIsEnable) {
        this.contraintes = Object.assign({}, this.contraintes, { stocks: this.getStockList() })
      } else {
        delete this.contraintes.stocks
      }
	this.$emit("changePreferences", this.contraintes)
    }
  },
  async mounted() {
    await this.getPortfolio();
    this.getClientConstraintes()
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-header-lg {
  padding: 1.5rem 2.5rem;
  height: auto;
}

.nav-pills,
.nav-tabs {
  margin-bottom: 1rem;
}

.nav-link {
  background-color: white;
  border: none;
  cursor: pointer;
  border-left: 1px solid lightgray
}
.nav-link:focus {
  outline:none !important;
}
li.nav-link:first-child {
  border-left: none
}
.nav-link-active {
  background-color: #F1F1F1;
  font-weight: bold;
  border-top: 1px solid lightgray;
}
.nav-tabs {
  color: #033c73 !important;
}
.nav-tabs {
  color: #033c73;
  font-weight: bold;
}

.nav-pills{
  color: #033c73 !important;
}
.nav-pills{
  background: #033c73;
}
.nav-pills{
  color: #fff !important;
}

body .card-header {
  height: auto;
  display: block;
  padding: 0.75rem 1.5rem;
  text-align: center;
}
body .card-header .btn-actions-pane-right {
  padding: 0.75rem 0 0;
}
body .card-header .actions-icon-btn {
  padding: 0;
}

.card-header.card-header-tab .card-header-title {
  display: inline-flex !important;
  line-height: 1;
}
.card-header.card-header-tab > .nav {
  margin: 0.75rem 0 -0.75rem;
  display: table !important;
  width: 100%;
}
.stock {
  padding: 10px;
  background-color: snow;
  width: max-content;
}
.stockSearch {
  height: 100%;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid lightgray;
}
.stockSearch:focus-visible {
  outline: none;
  border-bottom: 2px solid black;
}
.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  margin-top: 15px;
}

.pref-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #F1F1F1;
  text-align: center;
}

.pref-table th {
  padding: 0.75rem;
}

.stockBtn {
  padding: 15px 20px;
  border: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 3px lightgray;
  margin-bottom: 20px;
  margin-top: 10px;
}
</style>
