<template>
  <nav style="font-size:16px">
    <ul class="nav">
      <li class="nav-item">
        <span @click="clickLink('Dash')" :class="getLinkStyle('Dash', nav)">
          <i class="icon-grid" style="margin-left: 4px;"></i>
          {{$t('DASHBOARD')}}
        </span>
      </li>

      <li class="nav-item">
        <span @click="clickLink('Obj')" :class="getLinkStyle('Obj', nav)" v-if="objectifsProps > 0">
          <i class="fa fa-crosshairs"></i>
          {{$t('GOALS')}}
          <span class="badge badge-info" id="spanObjToHide"></span>
        </span>
      </li>
      <li class="nav-item">
        <span @click="clickLink('Pref')" :class="getLinkStyle('Pref', nav)">
          <i class="fa fa-asterisk"></i>
          {{$t('PREFERENCES')}}
        </span>
      </li>
      <li class="nav-item">
        <span @click="clickLink('ESG')" :class="getLinkStyle('ESG', nav)">
          <img src="../../../../public/img/icons/planet-earth.png" class="icon" alt="earth and environment" />
          {{$t('ESG')}}
        </span>
      </li>
      <li class="nav-item">
        <span @click="clickLink('Com')" :class="getLinkStyle('Com', nav)">
          <i class="fa fa-check-circle"></i>
          {{$t('COMPLIANCE')}}
        </span>
      </li>
      <li class="nav-item">
        <span @click="clickLink('Histo')" :class="getLinkStyle('Histo', nav)">
          <i class="fa fa-calendar-check-o"></i>
          {{$t('HISTORY')}}
        </span>
      </li>
      <li class="nav-item">
        <span @click="clickLink('Resume')" :class="getLinkStyle('Resume', nav)">
          <i class="fa fa-list-ol"></i>
          {{$t('SUMMARY')}}
        </span>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    objectifsProps: {
      type: Number,
      required: true,
      default: {},
    },
    nav: {
      type: String,
      required: true
    }
  },
  computed:{
    getLinkStyle() {
      return (link, nav) => { 
        let noStyle = "nav-link myNav";
        let style = "nav-link myNav btn-primary text-white"
        return link === nav ? style : noStyle
      } 
    }  
  },
  methods: {
    clickLink(val) {
      this.$emit("clicked", val);
    },
  },
};
</script>

<style>
.myNav {
  cursor: pointer;
}
.icon {
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  text-align: center;
}
</style>
