import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Clients from './views/Clients.vue';
import Dashboard from './views/Dashboard.vue';
import NewCompte from './views/NewCompte.vue';
import Convictions from './views/Convictions.vue';
import References from './views/References.vue';
import Optimisations from './views/Optimisations.vue';
import Contraintes from './views/Contraintes.vue';
import Connection from './views/Connection.vue';
import DashAdmin from './views/DashAdmin.vue';
import DashUsers from './views/DashUsers.vue';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Connection,
    },
    ,
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/new',
      name: 'Nouveau compte',
      component: NewCompte,
    },
    {
      path: '/clients',
      name: 'Clients',
      component: Clients,
    },
    {
      path: '/convictions',
      name: 'Convictions de marché',
      component: Convictions,
    },
    {
      path: '/dashboard/:id',
      name: 'Dashboard du client',
      component: Dashboard,
    },
    {
      path: '/references',
      name: 'Portefeuilles de référence',
      component: References,
    },
    {
      path: '/optimisation',
      name: 'Optimisation des portefeuilles',
      component: Optimisations,
    },
    {
      path: '/contraintes',
      name: 'Contraintes',
      component: Contraintes,
    },
    {
      path: '/admin',
      name: 'Dashboard Admin',
      component: DashAdmin,
    },
    {
      path: '/users',
      name: 'Dashboard Users',
      component: DashUsers,
    },
  ],
});
