<template>
  <div>
    <div style="padding: 0px">
      <div class="card-header">
        <strong>
          <i class="fa fa-tasks" style="margin-right: 10px" />
          {{ $t(titleFormat) }}
        </strong>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <div class="text-center">
              <strong class="resize">
                <!------ i don't understant why there is this 2 conditions  ----->
                <span v-if="$i18n.locale === 'en'">{{
                  project.TypeProjet == 'EtudesEnfants' ? $t('Annual') : null
                }}</span>

                {{ $t('OBJECTIVE_SIMULATION') }}

                <!------ i don't understant why there is this 2 conditions  ----->
                <span v-if="$i18n.locale === 'fr'">{{
                  project.TypeProjet == 'EtudesEnfants' ? $t('Annual') : null
                }}</span>

                {{ formatNumber(project.MontantCible) }} €
              </strong>
              <div style="margin-top: 15px">
                <span>
                  {{ $t('RECYCLING_SURPLUSES') }}
                  <input
                    type="checkbox"
                    @change="checkedRecycle()"
                    :checked="recyclage[project.Name] == 1"
                  />
                </span>
              </div>
              <div style="margin-top: 25px; text-align: center">
                <strong class="resize">
                  {{ $t('ALLOCATED_CAPITAL') }} &nbsp;
                  <span>{{ $formatNumberKM(allocationModif) }}€</span>
                </strong>
                <div style="margin-top: 5px;">

                <strong class="resize">
                  {{ $t('CONTRIBUTION_PLUS_CAPITAL') }} &nbsp;
                  <span>{{ $formatNumberKM(allocationTotal) }}€</span>
                </strong>
                </div>
                <br />
                <strong class="resize">
                  {{ $t('RISK_LEVEL') }} &nbsp;
                  <span>{{ roundTwoDigit(riskNote) }}</span>
                </strong>
                </div>
                <!---
              </div>
              <div style="margin-top: 10px; text-align: center" class="resize">
                <button
                  class="rangeButton"
                  @click="
                    clickAllocationModif('substract', 'allocK' + project.Name)
                  "
                  @mousedown="
                    startAllocationModif('substract', 'allocK' + project.Name)
                  "
                  @mouseleave="stopAllocationModif"
                  @mouseup="stopAllocationModif"
                >
                  -
                </button>
                <slidersimulation
                  @slided="slider"
                  :name="'allocK' + project.Name"
                  :min="0"
                  :max="initAlloc * 3"
                  :step="1000"
                  :value="allocationModif"
                />
                <button
                  class="rangeButton"
                  @click="clickAllocationModif('add', 'allocK' + project.Name)"
                  @mousedown="
                    startAllocationModif('add', 'allocK' + project.Name)
                  "
                  @mouseleave="stopAllocationModif"
                  @mouseup="stopAllocationModif"
                >
                  +
                </button>
              </div>

            </div>
            <div>
              <div style="margin-top: 25px; text-align: center">
                <strong class="resize">
                  {{ $t('RISK_LEVEL') }} &nbsp;
                  <span>{{ roundTwoDigit(riskNote) }}</span>
                </strong>
              </div>
              <div style="margin-top: 10px; text-align: center">
                <slidersimulation
                  @slided="sliderRisk"
                  :name="'risk' + project.Name"
                  :min="1"
                  :max="5"
                  :step="0.1"
                  :value="riskNote"
                />
                <div style="display: flex; justify-content: center">
                  <p style="font-size: 12px; margin-right: 60px">
                    {{ $t('PRIORITY_SECOND') }}
                  </p>
                  <p style="font-size: 12px; margin-left: 60px">
                    {{ $t('AUDACIOUS') }}
                  </p>
                </div>
              </div>
              --->
            <div>
              <div style="margin-top: 25px; text-align: center">
                <strong class="resize">
                  {{ $t('SLIDER_PRIORITY') }} &nbsp;
                  <span>{{ Math.abs(roundTwoDigit(mixSlider)) }}</span>
                </strong>
              </div>
              <div style="margin-top: 10px; text-align: center">
                <slidersimulation
                  @slided="sliderMix"
                  :name="'slid' + project.Name"
                  :min="-2"
                  :max="2"
                  :step="0.1"
                  :value="mixSlider"
                />
                <div style="display: flex; justify-content: center">
                  <p style="font-size: 12px; margin-right: 60px">
                    {{ $t('MORE_PRIORITY') }}
                  </p>
                  <p style="font-size: 12px; margin-left: 60px">
                    {{ $t('NICE_TO_HAVE') }}
                  </p>
                </div>
            </div>
            </div>
            </div>
            <div
              v-if="project.TypeProjet == 'InvestissementImmobilier'"
              class="alert alert-info"
              style="margin-bottom: 15px"
            >
              <span style="font-size: 15px" name="estate_mensuel">{{
                $t('MONTHLY_LOAN_REPAYMENT')
              }}</span>

              <div style="text-align: right">{{ rembEmprunts }}</div>
            </div>
            <div class="alert alert-info" style="margin-bottom: 15px">
              <span style="font-size: 15px" name="estate_mensuel">{{
                $t('EXPECTATION')
              }}</span>
              <div style="text-align: right">
                {{ $formatNumberKM(project.Esperance) }}€
              </div>
            </div>

            <div class="alert alert-info">
              <span
                style="font-size: 15px; margin-bottom: 15px"
                name="estate_mensuel"
                >{{ $t('PROBABILITY_MEETING_EXCEEDING_GOAL') }}
              </span>
              <div style="text-align: right">
                {{ formatNumber(project.Proba) }}%
              </div>
            </div>
          </div>
          <div
            class="col-sm-5"
            style="
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              margin-left: -5px;
            "
          >
            <BarSimulation
              :proba="Math.round(project.Proba)"
              :cible="formatNumber(project['MontantCible'])"
              :cibleInt="project['MontantCible']"
              :annee="project.AnneeCible"
              :name="project.Name"
              :gen="gen"
              :quantfront="quantilesfront"
              style="align-self: end"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import BarSimulation from '@/components/agent/nouveau_compte/barchart_simulation.vue';
import sliderSimulation from '@/components/autre/slider.vue';

Vue.component('slidersimulation', sliderSimulation);

export default {
  name: 'ProjectSimulation',
  components: {
    BarSimulation,
  },
  props: {
    project: Object,
    obj: Object,
    quantiles: Array,
    recyclage: Object,
    gen: String,
    quantilesfront: Array,
  },
  watch: {
    project: function(val) {
      this.allocationModif = val.AllocKInterm, //val.AllocKZero;
      this.initAlloc = val.AllocKZero;
      this.riskNote = val.Note;
      this.mixSlider = val.Sliders;
      this.gen = this.guidGenerator();
      this.allocationTotal = val.TotVersmtsPrjts;
    },
  },
  data() {
    return {
      allocationModif: this.project.AllocKInterm, //this.project.AllocKZero,
      initAlloc: this.project.AllocKZero,
      riskNote: this.project.Note,
      allocationModofiInterval: false,
      mixSlider: this.project.Sliders,
      allocationTotal: this.project.TotVersmtsPrjts
    };
  },
  computed: {
    maxCapital() {
      return this.project.AllocK * 3;
    },
    rembEmprunts() {
      let remb = null;
      if (this.project.RembEmprunts !== null) {
        remb = this.$formatNumberKM(this.project.RembEmprunts);
        remb = `${remb}€`;
      } else {
        remb = 'Inconnu';
      }
      return remb;
    },
    titleFormat() {
      return this.project.TypeProjet == 'EtudesEnfants'
        ? 'Etudes de ' + this.project.Name
        : this.project.TypeProjet == 'Retraite'
        ? 'Complément de ' + this.project.Name
        : this.project.Name;
    },
  },
  methods: {
    guidGenerator() {
      let S4 = function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      );
    },
    roundTwoDigit(number) {
      return parseFloat(Math.round(number * 10) / 10).toFixed(1);
    },
    formatNumber(number) {
      return Math.round(number)
        .toString()
        .replace(' ', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    clickAllocationModif(type, projectName) {
      type === 'add'
        ? (this.allocationModif += 1000)
        : (this.allocationModif -= 1000);
      this.slider({ name: projectName, data: this.allocationModif });
    },
    startAllocationModif(type, projectName) {
      const timeInterval = 250;
      const rangeValue = 1000;

      if (!this.allocationModofiInterval) {
        type === 'add'
          ? (this.allocationModofiInterval = setInterval(() => {
              this.allocationModif += rangeValue;
              this.slider({ name: projectName, data: this.allocationModif });
            }, timeInterval))
          : (this.allocationModofiInterval = setInterval(() => {
              this.allocationModif -= rangeValue;
              this.slider({ name: projectName, data: this.allocationModif });
            }, timeInterval));
      }
    },
    stopAllocationModif() {
      clearInterval(this.allocationModofiInterval);
      this.allocationModofiInterval = false;
    },
    slider(val) {
      val.init = this.initAlloc;
      val.name = this.project.Name;
      this.$emit('changed', val);
      this.allocationModif = val.data;
    },

    sliderRisk(val) {
      val.name = this.project.Name;
      this.riskNote = val.data;
      this.$emit('risked', val);
    },
    sliderMix(val) {
      val.name = this.project.Name;
      this.mixSlider = val.data;
      this.$emit('mixedSlider', val);
    },
    checkedRecycle() {
      let val = {
        name: this.project.Name,
        data: this.recyclage[this.project.Name] == 1 ? null : 1,
      };
      this.$emit('recycled', val);
    },
  },
};
</script>

<style>
strong {
  font-size: 17px;
}
.resize {
  white-space: nowrap;
}
</style>
