<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog modal-primary">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style="text-align: center">
                {{ $t('PROPERTY_INVESTMENT') }}
              </h5>
              <button type="button" class="close" @click="hideModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12" style="font-size: 12px; text-align: left">
                  <div class="card">
                    <div class="card-header" style="font-size: 16px">
                      <i class="fa fa-plus" style="margin-right: 10px"></i>
                      <strong>{{ $t('ADDITIONAL_DATA') }}</strong>
                    </div>
                    <div class="card-body">
                      <div style="margin-bottom: 5%">
                        <span>
                          <strong>{{ $t('ADD_REAL_ESTATE_PROJECT') }}</strong>
                        </span>
                        <input type="text" v-model="newChild" />
                        <button
                          class="btn btn-primary btn-sm myNavInfo next"
                          @click="saveChild()"
                        >
                          {{ $t('CONFIRM') }}
                        </button>
                      </div>
                      <select
                        v-if="data.length > 0"
                        class="form-control"
                        name
                        id="comboChild"
                        v-on:change="comboClick"
                      >
                        <option
                          v-for="(child, index) in data"
                          :value="index"
                          :selected="indexChild == index"
                          :key="child+index"
                        >
                          {{ child.name }}
                        </option>
                      </select>
                      <br />
                      <form
                        v-if="data.length > 0"
                        action
                        method="post"
                        class="form-horizontal"
                      >
                        <div class="form-group row">
                          <div class="col-md-12">
                            <div class="input-group">
                              <span class="input-group-addon">
                                <strong>{{ $t('PROPERTY_VALUE') }}</strong>
                              </span>
                              <BaseInputFormatted
                                _id="txtHouseModalImmo"
                                _name="txtHouseModalImmo"
                                class="form-control"
                                v-model="data[indexChild].value"
                                :formatOutsideInput="formatOutsideInput"
                                :formatInsideInput="formatInsideInput"
                                :insideNewvalue="insideNewvalue"
                                :outsideNewvalue="outsideNewvalue"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-md-12">
                            <div class="input-group">
                              <span class="input-group-addon">
                                <strong>{{ $t('PURCHASE_HORIZON') }}</strong>
                              </span>
                              <input
                                type="text"
                                id="txtStartModalImmo"
                                name="txtStartModalImmo"
                                class="form-control"
                                v-model.lazy="data[indexChild].date"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <strong>% {{ $t('BORROWED') }}</strong>
                                  </span>
                                  <select
                                    name="selectTauxPretImmo"
                                    id="selectTauxPret_Immo"
                                    class="form-control"
                                    v-model="data[indexChild].percent"
                                  >
                                    <option
                                      value="0"
                                      :selected="data[indexChild].percent == 0"
                                    >
                                      0 %
                                    </option>
                                    <option
                                      value="0.1"
                                      :selected="
                                        data[indexChild].percent == 0.1
                                      "
                                    >
                                      10%
                                    </option>
                                    <option
                                      value="0.2"
                                      :selected="
                                        data[indexChild].percent == 0.2
                                      "
                                    >
                                      20%
                                    </option>
                                    <option
                                      value="0.3"
                                      :selected="
                                        data[indexChild].percent == 0.3
                                      "
                                    >
                                      30%
                                    </option>
                                    <option
                                      value="0.4"
                                      :selected="
                                        data[indexChild].percent == 0.4
                                      "
                                    >
                                      40%
                                    </option>
                                    <option
                                      value="0.5"
                                      :selected="
                                        data[indexChild].percent == 0.5
                                      "
                                    >
                                      50%
                                    </option>
                                    <option
                                      value="0.6"
                                      :selected="
                                        data[indexChild].percent == 0.6
                                      "
                                    >
                                      60%
                                    </option>
                                    <option
                                      value="0.7"
                                      :selected="
                                        data[indexChild].percent == 0.7
                                      "
                                    >
                                      70%
                                    </option>
                                    <option
                                      value="0.8"
                                      :selected="
                                        data[indexChild].percent == 0.8
                                      "
                                    >
                                      80%
                                    </option>
                                    <option
                                      value="0.9"
                                      :selected="
                                        data[indexChild].percent == 0.9
                                      "
                                    >
                                      90%
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="form-group row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <strong>{{ $t('TERM_OF_LOAN') }}</strong>
                                  </span>
                                  <select
                                    name="selectDureePretImmo"
                                    id="selectDureePret_Immo"
                                    class="form-control"
                                    v-model="data[indexChild].duree"
                                  >
                                    <option
                                      value="5"
                                      :selected="data[indexChild].duree == 5"
                                    >
                                      5
                                    </option>
                                    <option
                                      value="10"
                                      :selected="data[indexChild].duree == 10"
                                    >
                                      10
                                    </option>
                                    <option
                                      value="15"
                                      :selected="data[indexChild].duree == 15"
                                    >
                                      15
                                    </option>
                                    <option
                                      value="20"
                                      :selected="data[indexChild].duree == 20"
                                    >
                                      20
                                    </option>
                                    <option
                                      value="25"
                                      :selected="data[indexChild].duree == 25"
                                    >
                                      25
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <strong>{{
                                      $t('THE_SALE_PROPERTY')
                                    }}</strong>
                                  </span>
                                  <div class="col-md-6">
                                    <div class="radio">
                                      <label for="radio2">
                                        <input
                                          type="radio"
                                          id="radio2"
                                          name="radios"
                                          value="true"
                                          v-model="data[indexChild].vente"
                                          :checked="
                                            data[indexChild].vente == true
                                          "
                                        />
                                        {{ $t('YES') }}
                                      </label>
                                    </div>
                                    <div class="radio">
                                      <label for="radio">
                                        <input
                                          type="radio"
                                          id="radio"
                                          name="radios"
                                          value="false"
                                          v-model="data[indexChild].vente"
                                          :checked="
                                            data[indexChild].vente == false
                                          "
                                        />
                                        {{ $t('NO') }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="data[indexChild].vente" class="form-group row">
                              <div class="col-md-12">
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    <strong>{{ $t('EXACT_AMOUNT') }}</strong>
                                  </span>
                                  <input
                                    type="text"
                                    id="txtHouseModalImmo"
                                    name="txtHouseModalImmo"
                                    class="form-control"
                                    v-model.lazy="data[indexChild].venteAmount"
                                  />
                                </div>
                              </div>
                            </div>
                            <span
                              @click="deleteChild()"
                              class="btn btn-primary btn-sm myNavInfo next"
                              >{{ $t('DELETE') }}</span
                            >
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span
                @click="save()"
                class="btn btn-primary btn-sm myNavInfo next"
                >{{ $t('SAVE') }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import BaseInputFormatted from '@/components/autre/BaseInputFormatted'

export default {
  components: {
    BaseInputFormatted
  },
  data() {
    return {
      data: [],
      indexChild: 0,
      newChild: '',
      formatInsideInput: /[^\d\.]/g,
      formatOutsideInput: /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      insideNewvalue: '',
      outsideNewvalue: '$1 '
    };
  },
  props: {
    dataProps: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$emit('clicked', 'immo');
    },
    save() {
      this.$emit('saved', {
        name: 'immo',
        data: this.data,
      });
      this.$emit('clicked', 'immo');
    },
    comboClick() {
      let lst = document.getElementById('comboChild');
      let str = lst.options[lst.selectedIndex].value;
      this.indexChild = str;
    },
    saveChild() {
      this.data.push({
        name: this.newChild,
        createdAt: new Date().toISOString(),
      });
      this.newChild = '';
      this.indexChild = this.data.length == 1 ? 0 : this.indexChild + 1;
    },
    deleteChild() {
      this.data.splice(this.indexChild, 1);
      this.indexChild = 0;
    },
  },

  mounted() {
    let ctx = this;
    this.data = JSON.parse(JSON.stringify(ctx.dataProps));
  },
};
</script>
<style></style>
