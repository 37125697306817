<template>
  <div class="sticky text-center">
    <span @click="refresh()" class="btn btn-primary" :style="{cursor:formatDelta(graph.deltaAllocK) == 0 ? 'pointer' : null}">{{$t('REFRESH')}}</span>
    <span v-if="saveProps" @click="save()" class="btn btn-primary" style="cursor:pointer; margin-left: 10px;">{{$t('SAVE')}}</span>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
    data() {
	return {
	    dataObjectifs: 0,
	    year: 0,
	    isShow: {
		inv: true,
		ret: false,
	    },
	    targetProb: 0,
	    targetAmountRet: 0,
	    showPlus: false,
	    graph: {},
	};
    },
    props: {
	graphProps: {
	    type: Object,
	    require: true,
	},
	dummyid: {
	    type: String,
	},
	saveProps: {
	    type:Boolean,
	    require: false,
	    default: false
	}
    },
    watch: {
	dummyid: function (val) {
	    this.graph = {};
	    this.graph = this.graphProps;
	},
	graphProps: {
	    handler: function (val) {
		this.graph = this.graphProps;
		this.year = val.current_year;
	    },
	    deep: true,
	},
    },
    computed: {
	...mapState({
	    data: (state) => state.new_Compte,
	}),
	dataObjectifsFormat: {
	    get: function () {
		return this.formatInt(this.dataObjectifs);
	    },
	    set: function (val) {
		this.dataObjectifs = val.toString().replace(" ", "");
	    },
	},
    },
    methods: {
	...mapActions(["setSimulation", "setRiskNote"]),
	formatInt(x) {
	    return Math.round(x)
		.toString()
		.replace(" ", "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	},
	formatDelta(x) {
	    if (x === undefined) return 0;
	    return Math.round(x)
		.toString()
		.replace(" ", "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	},
	btnPlus() {
	    this.showPlus ? (this.showPlus = false) : (this.showPlus = true);
	    this.$emit("plusMoinShow", this.showPlus);
	},
	refresh() {
	    if(this.formatDelta(this.graph.deltaAllocK) == 0){
		this.$emit("refreshed");
	    }
	},
	save(){
	    this.$emit("saved");
	}
    },
    created() {
	let ctx = this;

	this.$store.watch(
	    function (state) {
		return state.new_Compte;
      },
      function (val) {
        //ctx.setNewCompte(val);
      },
      {
        deep: true,
      }
    );
  },
};
</script>

<style>
.refcolor {
  opacity: 1;
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid #222;
  vertical-align: middle;
  margin-right: 5px;
}

#AMUpSpan {
  background: #0099cc;
}

#AMMidSpan {
  background: #c3efec;
}

#AMLowSpan {
  background: #8c8c8c;
}

.sticky {
  position: fixed;
  bottom: 0;
  width: 65%;
  height: 70px;
  margin: 7px auto;
  margin-left: -60px;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  z-index: 15000;
}
</style>
